import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  GET_AFFILIATES_PARTNER,
  GET_AFFILIATES_PARTNERS,
  POST_AFFILIATES_PARTNER,
  PUT_AFFILIATES_PARTNER,
} from 'api/paths/constants';
import { ApiResponseType, IPagination, IPartner, IPartnerCreate, Querying } from 'types';
import { RootState } from 'store/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import fetcher from 'utils/fetcher';
import { EMPTY_ARRAY } from 'constant';

type PartnersStateType = {
  data: IPartner[];
  partner: IPartner | null;
  isLoading: boolean;
};

type GetPartnerParams = IPagination | undefined;

type UsePartnersActionsType = {
  get: (options?: GetPartnerParams) => void;
  post: (data: IPartnerCreate) => void;
  update: (data: IPartner) => void;
  getSingle: (id: number) => void;
  updateInfo: (data: Partial<IPartner>) => void;
};

const initialState: PartnersStateType = {
  isLoading: false,
  partner: null,
  data: EMPTY_ARRAY,
};

const getPartners = createAsyncThunk<ApiResponseType<IPartner>, GetPartnerParams>(
  'partners/get',
  async (options: GetPartnerParams) => {
    return fetcher({
      url: GET_AFFILIATES_PARTNERS,
      params: options as Querying<GetPartnerParams>,
    });
  },
);

const postPartner = createAsyncThunk<ApiResponseType<IPartner>, IPartnerCreate>(
  'partners/post',
  async (body: IPartnerCreate) => {
    return fetcher({
      url: POST_AFFILIATES_PARTNER,
      method: 'POST',
      body,
    });
  },
);

const updatePartner = createAsyncThunk<ApiResponseType<IPartner>, Partial<IPartner>>(
  'partners/put',
  async (body: Partial<IPartner>) => {
    const { id, ...rest } = body;
    return fetcher({
      url: PUT_AFFILIATES_PARTNER(id as number),
      method: 'PUT',
      body: rest,
    });
  },
);

const getPartner = createAsyncThunk<ApiResponseType<IPartner>, number>('partner/get', async (body: number) => {
  return fetcher({
    url: GET_AFFILIATES_PARTNER(body),
    method: 'GET',
  });
});

const partnersSlice = createSlice({
  name: 'partners',
  initialState,
  reducers: {
    updatePartnerInfo(state, action: PayloadAction<Partial<IPartner>>): void {
      state.data = state.data.map((i: IPartner) => (i.id === action.payload?.id ? { ...i, ...action.payload } : i));
      state.partner = { ...state.partner, ...action.payload } as IPartner;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getPartners.pending.type, (state): void => {
        state.isLoading = true;
      })
      .addCase(getPartners.fulfilled.type, (state, action: PayloadAction<{ data: IPartner[] }>): void => {
        state.isLoading = false;
        state.data = action.payload?.data;
      })
      .addCase(getPartners.rejected.type, (state): void => {
        state.isLoading = false;
        state.data = EMPTY_ARRAY;
      })
      .addCase(postPartner.fulfilled.type, (state, action: PayloadAction<{ data: IPartner }>): void => {
        state.data = [action.payload?.data, ...state.data];
      })
      .addCase(updatePartner.fulfilled.type, (state, action: PayloadAction<{ data: IPartner }>): void => {
        state.data = state.data.map((i: IPartner) => (i.id === action.payload?.data?.id ? action.payload?.data : i));
        state.partner = action.payload?.data;
      })
      .addCase(getPartner.pending.type, (state): void => {
        state.isLoading = true;
      })
      .addCase(getPartner.rejected.type, (state): void => {
        state.partner = null;
        state.isLoading = false;
      })
      .addCase(getPartner.fulfilled.type, (state, action: PayloadAction<{ data: IPartner }>): void => {
        state.partner = action.payload?.data;
        state.isLoading = false;
      }),
});

const usePartners = (): [PartnersStateType, UsePartnersActionsType] => {
  const state = useAppSelector((storeState: RootState) => storeState.partners);
  const dispatch = useAppDispatch();

  const actions = {
    get: (options: GetPartnerParams): void => {
      dispatch(getPartners(options));
    },
    post: (model: IPartnerCreate): void => {
      dispatch(postPartner(model));
    },
    update: (model: Partial<IPartner>): void => {
      dispatch(updatePartner(model));
    },
    getSingle: (id: number): void => {
      dispatch(getPartner(id));
    },
    updateInfo: (model: Partial<IPartner>): void => {
      dispatch(partnersSlice.actions.updatePartnerInfo(model));
    },
  };
  return [state, actions];
};

export { partnersSlice };

export default usePartners;
