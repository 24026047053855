import { ApiResponseType, IDomain, IDomainCreateDto, IDomainUpdateDto } from 'types';
import fetcher from 'utils/fetcher';

import {
  GET_AFFILIATES_PARTNER_ALL_DOMAINS,
  GET_AFFILIATES_PARTNER_ACTIVE_DOMAINS,
  POST_AFFILIATES_DOMAINS,
  PUT_AFFILIATES_DOMAIN,
  DELETE_AFFILIATES_DOMAIN,
} from '../paths/constants';

export const getDomains = (): Promise<ApiResponseType<IDomain[]>> =>
  fetcher<IDomain[]>({
    method: 'GET',
    url: GET_AFFILIATES_PARTNER_ALL_DOMAINS,
  });

export const getActiveDomains = (): Promise<ApiResponseType<IDomain[]>> =>
  fetcher<IDomain[]>({
    method: 'GET',
    url: GET_AFFILIATES_PARTNER_ACTIVE_DOMAINS,
  });

export const createDomain = (model: IDomainCreateDto): Promise<ApiResponseType<IDomain>> =>
  fetcher<IDomain, IDomainCreateDto>({
    method: 'POST',
    url: POST_AFFILIATES_DOMAINS,
    body: model,
  });

export const updateDomain = (model: Partial<IDomainUpdateDto>, id: number): Promise<ApiResponseType<IDomain>> =>
  fetcher<IDomain, Partial<IDomainUpdateDto>>({
    method: 'PUT',
    url: PUT_AFFILIATES_DOMAIN(id),
    body: model,
  });

export const deleteDomain = (id: number): Promise<ApiResponseType<IDomain>> =>
  fetcher<IDomain, Partial<IDomainUpdateDto>>({
    method: 'DELETE',
    url: DELETE_AFFILIATES_DOMAIN(id),
  });
