import { AffiliatePermissionsEnum } from 'enums/accessTemplates';

import { OptionType } from 'types';
import { PERMISSIONS_OPTIONS } from 'configs';

export const generatePermissionList = (existPermissions: string[]): OptionType[] => {
  if (
    existPermissions.includes(AffiliatePermissionsEnum.SUPER_ADMIN) ||
    existPermissions.includes(AffiliatePermissionsEnum.ADMIN)
  ) {
    return PERMISSIONS_OPTIONS.filter((i) => i.id !== AffiliatePermissionsEnum.SUPER_ADMIN);
  } else {
    return PERMISSIONS_OPTIONS.filter((i) => existPermissions.includes(i.id));
  }
};

export const generateDefaultValue = (
  existPermissions: string[],
  selectedPermission: (boolean | AffiliatePermissionsEnum | undefined)[],
): boolean[] => {
  const options = generatePermissionList(existPermissions);
  const permissions: boolean[] = [];
  options.forEach((i) => {
    permissions.push(selectedPermission.includes(i.id as AffiliatePermissionsEnum));
  });

  return permissions;
};

export const AccessChangePartnerPermissions = [AffiliatePermissionsEnum.SUPER_ADMIN];

export const AccessCreatePermissions = [AffiliatePermissionsEnum.SUPER_ADMIN, AffiliatePermissionsEnum.ADMIN];

export const AccessCreateAffiliatePermissions = [AffiliatePermissionsEnum.SUPER_ADMIN, AffiliatePermissionsEnum.ADMIN];

export const AccessEditVerifiedAffiliatePermissions = [
  AffiliatePermissionsEnum.SUPER_ADMIN,
  AffiliatePermissionsEnum.ADMIN,
];

export const AccessEditPendingAffiliatePermissions = [
  AffiliatePermissionsEnum.SUPER_ADMIN,
  AffiliatePermissionsEnum.ADMIN,
];

export const AccessPlayerPermissions = [
  AffiliatePermissionsEnum.SUPER_ADMIN,
  AffiliatePermissionsEnum.ADMIN,
  AffiliatePermissionsEnum.AFFILIATE_USER,
];

export const AccessAffiliatesInfoPermissions = [AffiliatePermissionsEnum.SUPER_ADMIN, AffiliatePermissionsEnum.ADMIN];

export const AccessGetLinkPermissions = [AffiliatePermissionsEnum.AFFILIATE_USER];
