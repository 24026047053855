import { POST_WITHDRAW } from 'api/paths/constants';
import { ApiResponseType, IAffiliatePaymentWithdraw } from 'types';
import fetcher from 'utils/fetcher';

export const createWithdraw = (
  params: IAffiliatePaymentWithdraw,
): Promise<ApiResponseType<IAffiliatePaymentWithdraw>> =>
  fetcher<IAffiliatePaymentWithdraw, IAffiliatePaymentWithdraw>({
    method: 'POST',
    url: POST_WITHDRAW,
    body: params,
  });
