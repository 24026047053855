export enum ActivitySectionsEnum {
  REAL_TIME = 'realTime',
  PERIODICAL = 'periodical',
  BALANCE = 'balance',
  TRANSACTIONS = 'transactions',
}

export enum ActivityTypeEnum {
  CURRENCY_CODE = 'currencyCode',
  REVENUE_BALANCE = 'revenueBalance',
  CPA_BALANCE = 'cpaBalance',
  TOTAL_AFFILIATES_COUNT = 'totalAffiliatesCount',
  PENDING_AFFILIATES_COUNT = 'pendingAffiliatesCount',
  TOTAL_PLAYERS_COUNT = 'totalPlayersCount',
  AFFILIATE_COUNT = 'affiliateCount',
  AFFILIATE_REAL_BALANCE = 'affiliateRealBalance',
  PLAYER_COUNT = 'playerCount',
  PLAYER_REAL_BALANCE = 'playerRealBalance',
  PLAYER_BONUS_BALANCE = 'playerBonusBalance',
  REAL_WIN_AMOUNT = 'realWinAmount',
  REAL_WIN_PERCENTAGE = 'realWinPercentage',
  BONUS_WIN_AMOUNT = 'bonusWinAmount',
  REAL_BET_AMOUNT = 'realBetAmount',
  REAL_BET_PERCENTAGE = 'realBetPercentage',
  BONUS_BET_AMOUNT = 'bonusBetAmount',
  REAL_DEPOSIT_AMOUNT = 'realDepositAmount',
  REAL_DEPOSIT_PERCENTAGE = 'realDepositPercentage',
  REAL_DEPOSIT_ADJUSTMENT = 'realDepositAdjustment',
  REAL_WITHDRAW_AMOUNT = 'realWithdrawAmount',
  REAL_WITHDRAW_PERCENTAGE = 'realWithdrawPercentage',
  REAL_WITHDRAW_ADJUSTMENT = 'realWithdrawAdjustment',
  GGR = 'ggr',
  AFFILIATE_REVENUE_BALANCE = 'revenueBalance',
  AFFILIATE_CPA = 'cpaBalance',
  REAL_PROFITABILITY = 'realProfitability',
  REAL_BET_COUNT = 'realBetCount',
  REAL_WON_COUNT = 'realWonCount',
  BONUS_BET_COUNT = 'bonusBetCount',
  BONUS_WON_COUNT = 'bonusWonCount',
  BONUS_PROFITABILITY = 'bonusProfitability',
  REAL_WON_AMOUNT = 'realWonAmount',
  BONUS_WON_AMOUNT = 'bonusWonAmount',
  LAST_DEPOSIT_DATE = 'lastDepositDate',
  FIRST_DEPOSIT_DATE = 'firstDepositDate',
  LAST_WITHDRAW_DATE = 'lastWithdrawDate',
  LAST_DEPOSIT_AMOUNT = 'lastDepositAmount',
  FIRST_WITHDRAW_DATE = 'firstWithdrawDate',
  TOTAL_DEPOSIT_COUNT = 'totalDepositCount',
  TOTAL_WITHDRAW_COUNT = 'totalWithdrawCount',
  FIRST_DEPOSIT_AMOUNT = 'firstDepositAmount',
  LAST_WITHDRAW_AMOUNT = 'lastWithdrawAmount',
  TOTAL_DEPOSIT_AMOUNT = 'totalDepositAmount',
  TOTAL_WITHDRAW_AMOUNT = 'totalWithdrawAmount',
  FIRST_WITHDRAW_AMOUNT = 'firstWithdrawAmount',
  TOTAL_ADJUSTMENT_DEPOSIT_COUNT = 'totalAdjustmentDepositCount',
  TOTAL_ADJUSTMENT_WITHDRAW_COUNT = 'totalAdjustmentWithdrawCount',
  TOTAL_ADJUSTMENT_DEPOSIT_AMOUNT = 'totalAdjustmentDepositAmount',
  TOTAL_ADJUSTMENT_WITHDRAW_AMOUNT = 'totalAdjustmentWithdrawAmount',
}

export enum PeriodEnum {
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  WEEK = 'week',
  LAST_WEEK = 'lastWeek',
  MONTH = 'month',
  LAST_MONTH = 'lastMonth',
}
