import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  GET_AFFILIATES_SYSTEM_CURRENCIES,
  GET_AFFILIATES_PUBLIC_CURRENCIES,
  GET_AFFILIATES_PARTNER_CURRENCIES,
  POST_AFFILIATES_CURRENCY,
  PUT_AFFILIATES_CURRENCY,
} from 'api/paths/constants';
import { ApiResponseType, ICurrencyEnableDTO, IPublicCurrency, ICurrency } from 'types';
import { RootState } from 'store/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import fetcher from 'utils/fetcher';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'constant';

type UseAllCurrenciesStateType = {
  allCurrencies: IPublicCurrency[];
  partnerCurrencies: ICurrency[];
  publicCurrencies: IPublicCurrency[];
  isLoading: boolean;
};

type UseAllCurrenciesActionsType = {
  getPublic: () => void;
  getPartner: () => Promise<{ data: ICurrency[] } | undefined>;
  getAll: () => void;
  addPartnerCurrency: (data: ICurrencyEnableDTO) => Promise<void>;
  updatePartnerCurrency: (data: Partial<ICurrency>) => Promise<void>;
};

const initialState: UseAllCurrenciesStateType = {
  isLoading: false,
  allCurrencies: EMPTY_ARRAY,
  partnerCurrencies: EMPTY_ARRAY,
  publicCurrencies: EMPTY_ARRAY,
};

const getPublicCurrencies = createAsyncThunk<ApiResponseType<IPublicCurrency>>('getPublicCurrencies/get', async () => {
  return fetcher({
    url: GET_AFFILIATES_PUBLIC_CURRENCIES,
  });
});

const getPartnerCurrencies = createAsyncThunk<ApiResponseType<IPublicCurrency>>(
  'getPartnerCurrencies/get',
  async () => {
    return fetcher({
      url: GET_AFFILIATES_PARTNER_CURRENCIES,
    });
  },
);

const getAllCurrencies = createAsyncThunk<ApiResponseType<IPublicCurrency>>('getAllCurrencies/get', async () => {
  return fetcher({
    url: GET_AFFILIATES_SYSTEM_CURRENCIES,
  });
});

const addPartnerCurrency = createAsyncThunk<ApiResponseType<IPublicCurrency>, ICurrencyEnableDTO>(
  'addPartnerCurrency/updateField',
  async (body: ICurrencyEnableDTO) => {
    return fetcher({
      url: POST_AFFILIATES_CURRENCY,
      method: 'POST',
      body,
    });
  },
);

const updatePartnerCurrency = createAsyncThunk<ApiResponseType<ICurrency>, Partial<ICurrency>>(
  'updatePartnerCurrency/updateField',
  async (body: Partial<ICurrency>) => {
    const { id, ...rest } = body;
    return fetcher({
      url: PUT_AFFILIATES_CURRENCY(id as number),
      method: 'PUT',
      body: rest,
    });
  },
);

const currenciesSlice = createSlice({
  name: 'allCurrencies',
  initialState,
  reducers: EMPTY_OBJECT,
  extraReducers: (builder) =>
    builder
      .addCase(getPublicCurrencies.pending.type, (state): void => {
        state.isLoading = true;
      })
      .addCase(
        getPublicCurrencies.fulfilled.type,
        (state, action: PayloadAction<{ data: IPublicCurrency[] }>): void => {
          state.isLoading = false;
          state.publicCurrencies = action.payload?.data;
        },
      )
      .addCase(getPublicCurrencies.rejected.type, (state): void => {
        state.isLoading = false;
        state.publicCurrencies = EMPTY_ARRAY;
      })
      .addCase(getAllCurrencies.fulfilled.type, (state, action: PayloadAction<{ data: IPublicCurrency[] }>): void => {
        state.isLoading = false;
        state.allCurrencies = action.payload?.data;
      })
      .addCase(getPartnerCurrencies.fulfilled.type, (state, action: PayloadAction<{ data: ICurrency[] }>): void => {
        state.partnerCurrencies = action.payload?.data;
      })
      .addCase(addPartnerCurrency.fulfilled.type, (state, action: PayloadAction<{ data: ICurrency }>): void => {
        state.partnerCurrencies = [action.payload.data, ...state.partnerCurrencies];
        state.publicCurrencies = [action.payload.data, ...state.publicCurrencies];
      })
      .addCase(updatePartnerCurrency.fulfilled.type, (state, action: PayloadAction<{ data: ICurrency }>): void => {
        const data = action.payload.data;
        state.partnerCurrencies = state.partnerCurrencies.map((i: ICurrency) => (i.id === data.id ? { ...data } : i));
        state.publicCurrencies = data.isEnabled
          ? [{ id: data.id, isEnabled: data.isEnabled, code: data.code, name: data.name }, ...state.publicCurrencies]
          : state.publicCurrencies.filter((i) => i.id !== data?.id);
      }),
});

const useCurrencies = (): [UseAllCurrenciesStateType, UseAllCurrenciesActionsType] => {
  const state = useAppSelector((storeState: RootState) => storeState.currencies);
  const dispatch = useAppDispatch();

  const actions = {
    getPublic: (): void => {
      dispatch(getPublicCurrencies());
    },
    getPartner: async (): Promise<{ data: ICurrency[] } | undefined> => {
      const response = await dispatch(getPartnerCurrencies());
      return response.payload as Promise<{ data: ICurrency[] } | undefined>;
    },
    getAll: (): void => {
      dispatch(getAllCurrencies());
    },
    addPartnerCurrency: async (data: ICurrencyEnableDTO): Promise<void> => {
      await dispatch(addPartnerCurrency(data));
    },
    updatePartnerCurrency: async (data: Partial<ICurrency>): Promise<void> => {
      await dispatch(updatePartnerCurrency(data));
    },
  };

  return [state, actions];
};

export { currenciesSlice };

export default useCurrencies;
