import { Control, FieldPath, FieldValues, useController } from 'react-hook-form';

import Uploader, { IUploaderProps } from 'components/common/Uploader';

interface IFormUploaderProps<TFieldValues extends FieldValues = FieldValues> extends IUploaderProps {
  name: FieldPath<TFieldValues>;
  control?: Control<TFieldValues>;
}

const FormUploader = <TFieldValues extends FieldValues = FieldValues>({
  name,
  control,
  ...props
}: IFormUploaderProps<TFieldValues>): JSX.Element => {
  const { field, fieldState } = useController<TFieldValues>({
    name: name,
    control,
  });

  return <Uploader {...props} error={fieldState.error?.message} value={field.value} onChange={field.onChange} />;
};

export default FormUploader;
