import { FC, ReactNode } from 'react';
import { Box, SxProps } from '@mui/material';

const dividers = {
  '1:1': '100%',
  '4:3': '75%',
  '8:5': '62.5%',
  '3:2': '66.66%',
  '16:9': '56.25%',
  '3:4': '125%',
  '9:16': '156.25%',
  '16:4.5': '45%',
};

export interface AspectRatioProps {
  size?: number;
  divide?: '1:1' | '4:3' | '8:5' | '3:2' | '16:9' | '3:4' | '9:16';
  children: ReactNode;
  spacing?: number | string;
  widthPercent?: number | string;
}

const AspectRatio: FC<AspectRatioProps> = ({ spacing = 0, divide = '1:1', size = 1, children }) => {
  return (
    <Box
      sx={{
        flexBasis: '100%',
        position: 'relative',
        maxWidth: `calc(100% / ${size})`,
        paddingTop: `calc(${dividers?.[divide] || dividers['1:1']} / ${size})`,
      }}
    >
      <Box
        sx={{
          p: spacing,
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

interface AspectRatioWrapperProps {
  sx?: SxProps;
  children: ReactNode;
  spacing?: number;
}

const AspectRatioWrapper: FC<AspectRatioWrapperProps> = ({ spacing = -1, children, sx }) => (
  <Box display="flex" flexWrap="wrap" m={spacing} sx={sx}>
    {children}
  </Box>
);

export { AspectRatioWrapper };
export default AspectRatio;
