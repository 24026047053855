import {
  CREATE_PAYMENT_METHOD_POST,
  DELETE_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD_CURRENCY,
  GET_SETTINGS_PAYMENT_METHODS_ENABLED,
  POST_PARTNER_PAYMENT_METHODS,
  POST_PAYMENT_METHOD_CURRENCY,
  POST_PAYMENT_METHOD_CUSTOM_FIELD,
  POST_SETTINGS_PAYMENT_METHODS_CURRENCY,
  PUP_PAYMENT_METHOD,
  PUT_PARTNER_PAYMENT_METHOD_AVAILABLE,
  PUT_SETTING_PAYMENT_METHOD_CURRENCY,
  PUT_SETTING_PAYMENT_METHOD_CUSTOM_FIELD,
  PUT_SETTING_PAYMENT_METHOD_ENABLE,
} from 'api/paths/constants';
import {
  ApiResponseType,
  ICreatePaymentMethod,
  ICreateSettingsPaymentMethodCurrency,
  IPaymentMethodCurrency,
  IPaymentMethodCustomField,
  IPaymentMethodListDTO,
  IPaymentMethodPartner,
  ISettingsPaymentMethod,
  ISettingsPaymentMethodCurrency,
  PaginatedResponse,
} from 'types';
import fetcher from 'utils/fetcher';

export const createPaymentMethod = (params: ICreatePaymentMethod): Promise<ApiResponseType<IPaymentMethodListDTO>> =>
  fetcher<IPaymentMethodListDTO, ICreatePaymentMethod>({
    method: 'POST',
    url: CREATE_PAYMENT_METHOD_POST,
    body: params,
  });

export const updatePaymentMethod = (params: {
  id: number;
  logo: string;
  name: string;
}): Promise<ApiResponseType<IPaymentMethodListDTO>> =>
  fetcher<
    IPaymentMethodListDTO,
    {
      id: number;
      logo: string;
      name: string;
    }
  >({
    method: 'PUT',
    url: PUP_PAYMENT_METHOD(params.id),
    body: params,
  });

export const deletePaymentMethod = (id: IPaymentMethodListDTO['id']): Promise<unknown> =>
  fetcher({
    method: 'DELETE',
    url: DELETE_PAYMENT_METHOD(id),
  });

export const deletePaymentMethodCurrency = (id: IPaymentMethodCurrency['id']): Promise<unknown> =>
  fetcher({
    method: 'DELETE',
    url: DELETE_PAYMENT_METHOD_CURRENCY(id),
  });

export const createPaymentMethodCurrency = ({
  methodId,
  currencyCode,
}: {
  methodId: string;
  currencyCode: string;
}): Promise<ApiResponseType<IPaymentMethodCurrency>> =>
  fetcher<IPaymentMethodCurrency, { currencyCode: string }>({
    method: 'POST',
    body: { currencyCode },
    url: POST_PAYMENT_METHOD_CURRENCY(methodId),
  });

export const createPartnerPaymentMethod = (params: {
  systemMethodId: number;
  partnerId: string;
}): Promise<ApiResponseType<IPaymentMethodPartner>> =>
  fetcher<IPaymentMethodPartner, { systemMethodId: number }>({
    method: 'POST',
    url: POST_PARTNER_PAYMENT_METHODS,
    body: params,
  });

export const updatePartnerPaymentMethodAvailability = (
  id: number,
  isAvailable: boolean,
): Promise<ApiResponseType<IPaymentMethodPartner>> =>
  fetcher<IPaymentMethodPartner, { isAvailable: boolean }>({
    method: 'PUT',
    url: PUT_PARTNER_PAYMENT_METHOD_AVAILABLE(id),
    body: { isAvailable },
  });

export const updateSettingPaymentMethodEnable = (
  id: number,
  isEnabled: boolean,
): Promise<ApiResponseType<ISettingsPaymentMethod>> =>
  fetcher<ISettingsPaymentMethod, { isEnabled: boolean }>({
    method: 'PUT',
    url: PUT_SETTING_PAYMENT_METHOD_ENABLE(id),
    body: { isEnabled },
  });

export const createPaymentMethodCustomField = (params: {
  isRequired: boolean;
  name: string;
  partnerMethodId: number;
}): Promise<ApiResponseType<IPaymentMethodCustomField>> =>
  fetcher<IPaymentMethodCustomField, Partial<IPaymentMethodCustomField>>({
    method: 'POST',
    url: POST_PAYMENT_METHOD_CUSTOM_FIELD,
    body: params,
  });

export const updatePaymentMethodCustomField = (params: {
  id: number;
  isRequired: boolean;
  name: string;
}): Promise<ApiResponseType<IPaymentMethodCustomField>> =>
  fetcher<IPaymentMethodCustomField, Partial<IPaymentMethodCustomField>>({
    method: 'PUT',
    url: PUT_SETTING_PAYMENT_METHOD_CUSTOM_FIELD(params.id),
    body: params,
  });

export const createSettingsPaymentMethodCurrency = (
  params: ICreateSettingsPaymentMethodCurrency,
): Promise<ApiResponseType<ISettingsPaymentMethodCurrency>> =>
  fetcher<ISettingsPaymentMethodCurrency, ICreateSettingsPaymentMethodCurrency>({
    method: 'POST',
    body: params,
    url: POST_SETTINGS_PAYMENT_METHODS_CURRENCY,
  });

export const updateSettingsPaymentMethodCurrency = (params: {
  id: number;
  minTransactionAmount: number;
  maxTransactionAmount: number;
}): Promise<ApiResponseType<ISettingsPaymentMethodCurrency>> =>
  fetcher<ISettingsPaymentMethodCurrency, Partial<ISettingsPaymentMethodCurrency>>({
    method: 'PUT',
    url: PUT_SETTING_PAYMENT_METHOD_CURRENCY(params.id),
    body: params,
  });

export const getEnabledSettingsPaymentMethods = (): Promise<
  ApiResponseType<PaginatedResponse<ISettingsPaymentMethod>>
> =>
  fetcher<PaginatedResponse<ISettingsPaymentMethod>>({
    method: 'GET',
    url: GET_SETTINGS_PAYMENT_METHODS_ENABLED,
  });
