import type { FC } from 'react';
import { SyntheticEvent, useState, useEffect } from 'react';
import { BottomNavigation, BottomNavigationAction, Box } from '@mui/material';
import { FooterNavigationEnum } from 'enums/entity';
import { AffiliatePermissionsEnum } from 'enums/accessTemplates';

import useScreen from 'hooks/useScreen';
import ProfileIcon from 'components/common/icons/bulk/Profile';
import { translate } from 'utils/translate';
import NotificationIcon from 'components/common/icons/bulk/Notification';
import ScrollToTopFab from 'components/common/ScrollToTopFab';
import { AccessCreatePermissions } from 'utils/accessTemplates';
import AddCircle from 'components/common/icons/AddCircle';
import MoneyCircle from 'components/common/icons/Money';
import { getActiveDomains } from 'api/requests/domains';
import { OptionType } from 'types';
import ToolsIcon from 'components/common/icons/bulk/Tool';

import CreateAffiliateModal from '../MainModals/CreateAffiliateModal';
import ChangePasswordModal from '../MainModals/ChangePasswordModal';
import GetLinkModal from '../MainModals/GetLinkModal';
import WithdrawModal from '../MainModals/WithdrawModal';

import ProfileDrawer from './ProfileDrawer';
import sx from './styles';

type Props = {
  existPermission?: AffiliatePermissionsEnum;
};

const FooterNavigation: FC<Props> = ({ existPermission }) => {
  const [value, setValue] = useState<FooterNavigationEnum | null>(null);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [openGetLink, setOpenGetLink] = useState(false);
  const [openTransferModal, setOpenTransferModal] = useState(false);
  const [linkOptions, setLinkOptions] = useState<OptionType[]>([]);
  const { isMobile } = useScreen();

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        if (linkOptions?.length === 0 && isMobile) {
          const response = await getActiveDomains();
          const options = response.data?.map((i) => {
            return { id: i.name, name: i.name };
          });
          setLinkOptions(options ?? []);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [isMobile, linkOptions?.length]);

  if (!isMobile) {
    return null;
  }

  const handleChange = (event: SyntheticEvent, newValue: FooterNavigationEnum): void => {
    setValue(newValue);
  };

  const handleClose = (): void => {
    setValue(null);
  };

  const handleOpenChangePassword = (): void => {
    setValue(null);
    setOpenChangePassword(true);
  };

  return (
    <Box sx={sx.root}>
      <BottomNavigation value={value} onChange={handleChange}>
        <BottomNavigationAction
          disabled
          showLabel
          label={translate('Notification')}
          value={FooterNavigationEnum.NOTIFICATION}
          icon={<NotificationIcon />}
        />
        {AccessCreatePermissions.some((r) => r === existPermission) && (
          <BottomNavigationAction
            showLabel
            label={translate('Create')}
            value={FooterNavigationEnum.CREATE}
            icon={<AddCircle />}
          />
        )}
        {existPermission === AffiliatePermissionsEnum.AFFILIATE_USER && (
          <BottomNavigationAction
            showLabel
            label={translate('Withdraw')}
            value={FooterNavigationEnum.TRANSFER}
            icon={<MoneyCircle />}
            onClick={(): void => setOpenTransferModal(true)}
          />
        )}
        {existPermission === AffiliatePermissionsEnum.AFFILIATE_USER && (
          <BottomNavigationAction
            showLabel
            label={translate('Tools')}
            value={FooterNavigationEnum.GET_LINK}
            icon={<ToolsIcon />}
            onClick={(): void => setOpenGetLink(true)}
          />
        )}
        <BottomNavigationAction
          showLabel
          label={translate('Profile')}
          value={FooterNavigationEnum.PROFILE}
          icon={<ProfileIcon />}
        />
      </BottomNavigation>
      {value === FooterNavigationEnum.CREATE && <CreateAffiliateModal onClose={handleClose} />}
      {value === FooterNavigationEnum.PROFILE && (
        <ProfileDrawer
          open={true}
          onClose={handleClose}
          onChangePassword={handleOpenChangePassword}
          permission={existPermission}
        />
      )}
      {openTransferModal && <WithdrawModal onClose={(): void => setOpenTransferModal(false)} />}
      {openChangePassword && <ChangePasswordModal onClose={(): void => setOpenChangePassword(false)} />}
      {openGetLink && <GetLinkModal linkOptions={linkOptions} onClose={(): void => setOpenGetLink(false)} />}
      <ScrollToTopFab />
    </Box>
  );
};

export default FooterNavigation;
