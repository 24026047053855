export enum CommissionPlanType {
  CPA = 'CPA',
  REVENUE = 'Revenue Share',
  BOTH = 'Hybrid',
}

export enum CommissionPlanPeriodTypeEnum {
  DAYS = 'Days',
  WEEKS = 'Weeks',
  MONTHS = 'Months',
}
