import { EntityEnum } from 'enums/entity';

import { TranslationKey } from 'utils/translate';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const EMPTY_ARRAY = Object.freeze([]) as unknown as any[];
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const EMPTY_OBJECT = Object.freeze({}) as Record<string, any>;

export const ACTION_BUTTON_ID = 'action-button';
export const START_ADORMENT_ID = 'data-grid-start-adorment';
export const TAB_ROUTER_LAYOUT_ACTION_BUTTON_ID = 'tab-router-layout-action-button';

export const RESET_TOKEN_QUERY = 'passwordResetToken';
export const VERIFY_EMAIL_QUERY = 'verifyEmail';

export const ENTITY_TYPES_INFO = {
  [EntityEnum.PLAYER]: {
    label: 'Player' as TranslationKey,
    value: 'player',
    route: 'player',
  },
  [EntityEnum.AFFILIATE]: {
    label: 'Affiliate' as TranslationKey,
    value: 'affiliate',
    route: 'affiliate',
  },
};
export const PAYMENT_PROVIDER_SYSTEM_TITLE = 'System';
export const PAYMENT_METHOD_SYSTEM_TITLE = 'System';
export const PAYMENT_METHOD_SYSTEM_ID = 0;
export const PAYMENT_PROVIDER_SYSTEM_ID = '-1';
