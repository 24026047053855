import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const ToolsIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M19.5 7.49988L18.01 8.98988L15.01 5.98988L16.5 4.49988C16.92 4.07988 17.46 3.87988 18 3.87988C18.54 3.87988 19.08 4.07988 19.5 4.49988C20.33 5.32988 20.33 6.66988 19.5 7.49988Z"
      fill="inherit"
    />
    <path
      opacity="0.4"
      d="M18.0099 8.98999L6.49994 20.5C5.66994 21.33 4.32994 21.33 3.49994 20.5C2.66994 19.67 2.66994 18.33 3.49994 17.5L15.0099 5.98999L18.0099 8.98999Z"
      fill="inherit"
    />
    <path
      d="M9.95002 3.50002L10.36 2.11002C10.4 1.98002 10.36 1.84002 10.27 1.74002C10.18 1.64002 10.02 1.60002 9.89002 1.64002L8.50002 2.05002L7.11002 1.64002C6.98002 1.60002 6.84002 1.64002 6.74002 1.73002C6.64002 1.83002 6.61002 1.97002 6.65002 2.10002L7.05002 3.50002L6.64002 4.89002C6.60002 5.02002 6.64002 5.16002 6.73002 5.26002C6.83002 5.36002 6.97002 5.39002 7.10002 5.35002L8.50002 4.95002L9.89002 5.36002C9.93002 5.37002 9.96002 5.38002 10 5.38002C10.1 5.38002 10.19 5.34002 10.27 5.27002C10.37 5.17002 10.4 5.03002 10.36 4.90002L9.95002 3.50002Z"
      fill="inherit"
    />
    <path
      d="M5.95002 9.50002L6.36002 8.11002C6.40002 7.98002 6.36002 7.84002 6.27002 7.74002C6.17002 7.64002 6.03002 7.61002 5.90002 7.65002L4.50002 8.05002L3.11002 7.64002C2.98002 7.60002 2.84002 7.64002 2.74002 7.73002C2.64002 7.83002 2.61002 7.97002 2.65002 8.10002L3.05002 9.50002L2.64002 10.89C2.60002 11.02 2.64002 11.16 2.73002 11.26C2.83002 11.36 2.97002 11.39 3.10002 11.35L4.49002 10.94L5.88002 11.35C5.91002 11.36 5.95002 11.36 5.99002 11.36C6.09002 11.36 6.18002 11.32 6.26002 11.25C6.36002 11.15 6.39002 11.01 6.35002 10.88L5.95002 9.50002Z"
      fill="inherit"
    />
    <path
      d="M20.95 14.5L21.36 13.11C21.4 12.98 21.36 12.84 21.27 12.74C21.17 12.64 21.03 12.61 20.9 12.65L19.51 13.06L18.12 12.65C17.99 12.61 17.85 12.65 17.75 12.74C17.65 12.84 17.62 12.98 17.66 13.11L18.07 14.5L17.66 15.89C17.62 16.02 17.66 16.16 17.75 16.26C17.85 16.36 17.99 16.39 18.12 16.35L19.51 15.94L20.9 16.35C20.93 16.36 20.97 16.36 21.01 16.36C21.11 16.36 21.2 16.32 21.28 16.25C21.38 16.15 21.41 16.01 21.37 15.88L20.95 14.5Z"
      fill="inherit"
    />
  </SvgIcon>
);

export default ToolsIcon;
