import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const AffiliatesIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon width={22} height={22} viewBox="0 0 22 22" {...props}>
    <path
      opacity="0.4"
      d="M8.24992 1.83325C5.84825 1.83325 3.89575 3.78575 3.89575 6.18742C3.89575 8.54325 5.73825 10.4499 8.13992 10.5324C8.21325 10.5233 8.28659 10.5233 8.34159 10.5324C8.35992 10.5324 8.36909 10.5324 8.38742 10.5324C8.39659 10.5324 8.39659 10.5324 8.40575 10.5324C10.7524 10.4499 12.5949 8.54325 12.6041 6.18742C12.6041 3.78575 10.6516 1.83325 8.24992 1.83325Z"
      fill="inherit"
    />
    <path
      d="M12.9066 12.9709C10.3491 11.2659 6.1783 11.2659 3.60246 12.9709C2.4383 13.7501 1.79663 14.8042 1.79663 15.9317C1.79663 17.0592 2.4383 18.1042 3.5933 18.8742C4.87663 19.7359 6.5633 20.1667 8.24996 20.1667C9.93663 20.1667 11.6233 19.7359 12.9066 18.8742C14.0616 18.0951 14.7033 17.0501 14.7033 15.9134C14.6941 14.7859 14.0616 13.7409 12.9066 12.9709Z"
      fill="inherit"
      fillOpacity="0.4"
    />
    <path
      opacity="0.4"
      d="M18.3241 6.72834C18.4707 8.50668 17.2057 10.065 15.4549 10.2758C15.4457 10.2758 15.4457 10.2758 15.4366 10.2758H15.4091C15.3541 10.2758 15.2991 10.2758 15.2532 10.2942C14.3641 10.34 13.5482 10.0558 12.9341 9.53334C13.8782 8.69001 14.4191 7.42501 14.3091 6.05001C14.2449 5.30751 13.9882 4.62918 13.6032 4.05168C13.9516 3.87751 14.3549 3.76751 14.7674 3.73084C16.5641 3.57501 18.1682 4.91334 18.3241 6.72834Z"
      fill="inherit"
      fillOpacity="0.4"
    />
    <path
      d="M20.1576 15.2075C20.0842 16.0967 19.5159 16.8667 18.5626 17.3892C17.6459 17.8934 16.4909 18.1317 15.3451 18.1042C16.0051 17.5084 16.3901 16.7659 16.4634 15.9775C16.5551 14.8409 16.0142 13.75 14.9326 12.8792C14.3184 12.3934 13.6034 12.0084 12.8242 11.7242C14.8501 11.1375 17.3984 11.5317 18.9659 12.7967C19.8092 13.475 20.2401 14.3275 20.1576 15.2075Z"
      fill="inherit"
    />
  </SvgIcon>
);

export default AffiliatesIcon;
