import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Box, ClickAwayListener, Divider, Drawer } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { AffiliatePermissionsEnum } from 'enums/accessTemplates';

import useScreen from '../../../hooks/useScreen';

import SidebarMenu from './SidebarMenu';

const drawerWidth = 300;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'center',
}));

type Props = {
  open: boolean;
  setOpen: (value: boolean) => void;
  existPermission: AffiliatePermissionsEnum;
  logoPath: string;
};

const SidebarNavigation: FC<Props> = ({ open, setOpen, existPermission, logoPath = '' }) => {
  const theme = useTheme();
  const { isMobile } = useScreen();

  const handleDrawerClose = (): void => {
    isMobile && setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex', borderRadius: '0' }}>
      <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleDrawerClose}>
        <Drawer
          variant="persistent"
          anchor="left"
          open={open}
          onClose={handleDrawerClose}
          sx={{
            [theme.breakpoints.down('md')]: {
              width: `80%`,
            },
            [theme.breakpoints.up('md')]: {
              width: `${drawerWidth}px`,
            },
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              [theme.breakpoints.down('md')]: {
                width: `80%`,
              },
              [theme.breakpoints.up('md')]: {
                width: drawerWidth,
              },
              boxSizing: 'border-box',
              borderRadius: 0,
              bgcolor: 'custom.primary.scale.1000',
              color: 'custom.neutrals.scale.300',
              '.logo-wrapper': {
                flexGrow: 1,
                textAlign: 'center',
              },
            },
          }}
        >
          <DrawerHeader>
            <Link to="/dashboard" className="logo-wrapper">
              <img src={logoPath ?? '/assets/images/logo-dark.png'} width={200} height={60} alt="Unliment" />
            </Link>
            {isMobile && (
              <IconButton onClick={handleDrawerClose} sx={{ color: 'custom.neutrals.scale.200' }}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            )}
          </DrawerHeader>
          <Divider />
          <SidebarMenu handleDrawerClose={handleDrawerClose} existPermission={existPermission} />
        </Drawer>
      </ClickAwayListener>
    </Box>
  );
};

export default SidebarNavigation;
