export enum PermissionTemplateTypeEnum {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
  LAYERED = 'LAYERED',
}

export enum AffiliatePermissionsEnum {
  SUPER_ADMIN = 'affiliate_super_admin',
  ADMIN = 'affiliate_admin',
  AFFILIATE_USER = 'affiliate_user',
}
