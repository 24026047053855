import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const CommissionPlanIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M5 15C4.06 15 3.19 15.33 2.5 15.88C1.58 16.61 1 17.74 1 19C1 21.21 2.79 23 5 23C6.01 23 6.93 22.62 7.64 22C8.47 21.27 9 20.2 9 19C9 16.79 7.21 15 5 15ZM6 19.25C6 19.51 5.86 19.76 5.64 19.89L4.39 20.64C4.27 20.71 4.14 20.75 4 20.75C3.75 20.75 3.5 20.62 3.36 20.39C3.15 20.03 3.25999 19.57 3.62 19.36L4.50999 18.83V17.75C4.50999 17.34 4.84999 17 5.25999 17C5.66999 17 6.00999 17.34 6.00999 17.75V19.25H6Z"
      fill="inherit"
    />
    <path
      opacity="0.4"
      d="M19.48 12.95H21.5V11.51C21.5 9.44001 19.81 7.75 17.74 7.75H6.25999C4.18999 7.75 2.5 9.44001 2.5 11.51V15.88C3.19 15.33 4.06 15 5 15C7.21 15 9 16.79 9 19C9 19.75 8.79 20.46 8.42 21.06C8.21 21.42 7.94 21.74 7.63 22H17.74C19.81 22 21.5 20.31 21.5 18.24V17.05H19.6C18.52 17.05 17.53 16.26 17.44 15.18C17.38 14.55 17.62 13.96 18.04 13.55C18.41 13.17 18.92 12.95 19.48 12.95Z"
      fill="inherit"
    />
    <path
      d="M14.85 3.95012V7.75011H6.25999C4.18999 7.75011 2.5 9.44012 2.5 11.5101V7.84014C2.5 6.65014 3.23 5.59009 4.34 5.17009L12.28 2.17009C13.52 1.71009 14.85 2.62012 14.85 3.95012Z"
      fill="inherit"
    />
    <path
      d="M22.56 13.9699V16.03C22.56 16.58 22.12 17.0299 21.56 17.0499H19.6C18.52 17.0499 17.53 16.2599 17.44 15.1799C17.38 14.5499 17.62 13.9599 18.04 13.5499C18.41 13.1699 18.92 12.95 19.48 12.95H21.56C22.12 12.97 22.56 13.4199 22.56 13.9699Z"
      fill="inherit"
    />
    <path
      d="M14 12.75H7C6.59 12.75 6.25 12.41 6.25 12C6.25 11.59 6.59 11.25 7 11.25H14C14.41 11.25 14.75 11.59 14.75 12C14.75 12.41 14.41 12.75 14 12.75Z"
      fill="inherit"
    />
  </SvgIcon>
);

export default CommissionPlanIcon;
