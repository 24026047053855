import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import AppLayout from 'pages/App';
import AuthLayout from 'pages/Auth';
import useAuth from 'store/auth';
import useMount from 'hooks/useMount';
import { DEFAULT_TITLE } from 'configs';
import useDidMount from 'hooks/useDidMount';
import useCurrencies from 'store/currencies';
import useBranding from 'store/branding';
import useConfiguration from 'store/configuration';

const Pages: FC = () => {
  const { pathname } = useLocation();

  const [authState] = useAuth();
  const [brandingState, brandingActions] = useBranding();
  const [, currencyActions] = useCurrencies();
  const [, configurationActions] = useConfiguration();

  useMount(() => {
    brandingActions.get();
    currencyActions.getPublic();
    configurationActions.getPublic();
  });

  useDidMount(() => {
    document.title = brandingState.settings?.title || DEFAULT_TITLE;
  }, [brandingState.settings?.title]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (authState?.data?.accessToken && authState?.data?.affiliate?.id) {
    return <AppLayout />;
  }

  return <AuthLayout />;
};

export default Pages;
