import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useMemo } from 'react';

import { RootState } from 'store/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import fetcher from 'utils/fetcher';
import { GET_SETTINGS_PAYMENT_METHOD_CURRENCIES } from 'api/paths/constants';
import { ApiResponseType, ISaveOptions, PaginatedResponse, ISettingsPaymentMethodCurrency } from 'types';
import createSaveReducer from 'utils/createSaveReducer';
import { EMPTY_ARRAY } from 'constant';

type UseSettingsPaymentMethodCurrencyStateType = {
  data: PaginatedResponse<ISettingsPaymentMethodCurrency>;
  isLoading: boolean;
};

type UseSettingsPaymentMethodCurrencyActionsType = {
  get: (id: string) => Promise<{ data: ISettingsPaymentMethodCurrency } | undefined>;
  getLocalById: (id: number) => ISettingsPaymentMethodCurrency | undefined;
  save: (options: ISaveOptions<ISettingsPaymentMethodCurrency>) => void;
  addSettingsPaymentMethodCurrency: (data: ISettingsPaymentMethodCurrency) => void;
};

const initialState: UseSettingsPaymentMethodCurrencyStateType = {
  isLoading: false,
  data: {
    data: EMPTY_ARRAY,
    count: 0,
  },
};

const getSettingsPaymentMethodCurrency = createAsyncThunk<ApiResponseType<ISettingsPaymentMethodCurrency>, string>(
  'settingsPaymentMethodCurrency/get',
  async (id: string) => {
    return fetcher({
      url: GET_SETTINGS_PAYMENT_METHOD_CURRENCIES(id),
    });
  },
);

const settingsPaymentMethodCurrencySlice = createSlice({
  name: 'settingsPaymentMethodCurrency',
  initialState,
  reducers: {
    save: createSaveReducer(),
    addSettingsPaymentMethodCurrency(state, action: PayloadAction<ISettingsPaymentMethodCurrency>): void {
      state.data.data = [action.payload, ...state.data.data];
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getSettingsPaymentMethodCurrency.pending.type, (state): void => {
        state.isLoading = true;
      })
      .addCase(
        getSettingsPaymentMethodCurrency.fulfilled.type,
        (state, action: PayloadAction<{ data: PaginatedResponse<ISettingsPaymentMethodCurrency> }>) => {
          state.isLoading = false;
          state.data = { ...state.data, data: action.payload?.data.data };
        },
      )
      .addCase(getSettingsPaymentMethodCurrency.rejected.type, (state): void => {
        state.isLoading = false;
      }),
});

const useSettingsPaymentMethodCurrency = (): [
  UseSettingsPaymentMethodCurrencyStateType,
  UseSettingsPaymentMethodCurrencyActionsType,
] => {
  const state = useAppSelector((storeState: RootState) => storeState.settingsPaymentMethodCurrency);
  const dispatch = useAppDispatch();

  const actions = useMemo(
    () => ({
      get: async (id: string): Promise<{ data: ISettingsPaymentMethodCurrency } | undefined> => {
        const settingsPaymentMethodCurrency = await dispatch(getSettingsPaymentMethodCurrency(id));
        return settingsPaymentMethodCurrency.payload as Promise<{ data: ISettingsPaymentMethodCurrency } | undefined>;
      },
      getLocalById: (id: number) =>
        state.data.data.find((settingsPaymentMethodCurrency) => settingsPaymentMethodCurrency.id === id),
      save: (options: ISaveOptions<ISettingsPaymentMethodCurrency>): void => {
        dispatch(settingsPaymentMethodCurrencySlice.actions.save(options));
      },
      addSettingsPaymentMethodCurrency: (model: ISettingsPaymentMethodCurrency): void => {
        dispatch(settingsPaymentMethodCurrencySlice.actions.addSettingsPaymentMethodCurrency(model));
      },
    }),
    [dispatch, state.data.data],
  );

  return [state, actions];
};

export { settingsPaymentMethodCurrencySlice };

export default useSettingsPaymentMethodCurrency;
