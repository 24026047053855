import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  GET_AFFILIATES_INFO_PROPERTY,
  POST_AFFILIATES_INFO_PROPERTY,
  PUT_AFFILIATES_INFO_PROPERTY,
  DELETE_AFFILIATES_INFO_PROPERTY,
  GET_AFFILIATES_INFO_PROPERTY_PUBLIC,
} from 'api/paths/constants';
import { ApiResponseType, CustomFieldType } from 'types';
import { RootState } from 'store/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import fetcher from 'utils/fetcher';
import { EMPTY_ARRAY } from 'constant';

type UseConfigurationStateType = {
  fields: CustomFieldType[];
  isLoading: boolean;
};

type UseConfigurationActionsType = {
  get: () => Promise<void>;
  getPublic: () => Promise<void>;
  createField: (data: Omit<CustomFieldType, 'id'>) => Promise<void>;
  updateField: (data: CustomFieldType) => Promise<void>;
  deleteField: (id: number) => Promise<void>;
};

const initialState: UseConfigurationStateType = {
  fields: EMPTY_ARRAY,
  isLoading: false,
};

const getCustomFields = createAsyncThunk<ApiResponseType<CustomFieldType>>('allCurrencies/get', async () => {
  return fetcher({
    url: GET_AFFILIATES_INFO_PROPERTY,
  });
});

const getCustomPublicFields = createAsyncThunk<ApiResponseType<CustomFieldType[]>>(
  GET_AFFILIATES_INFO_PROPERTY_PUBLIC,
  async () => {
    return fetcher({
      method: 'GET',
      url: GET_AFFILIATES_INFO_PROPERTY_PUBLIC,
    });
  },
);

const createField = createAsyncThunk<ApiResponseType<CustomFieldType>, Omit<CustomFieldType, 'id'>>(
  'affiliate/createField',
  async (body: Omit<CustomFieldType, 'id'>) => {
    return fetcher({
      url: POST_AFFILIATES_INFO_PROPERTY,
      method: 'POST',
      body,
    });
  },
);

const updateField = createAsyncThunk<ApiResponseType<CustomFieldType>, CustomFieldType>(
  'affiliate/updateField',
  async (body: CustomFieldType) => {
    const { id, ...rest } = body;
    return fetcher({
      url: PUT_AFFILIATES_INFO_PROPERTY(id),
      method: 'PUT',
      body: rest,
    });
  },
);

const deleteField = createAsyncThunk<ApiResponseType<CustomFieldType>, number>(
  'affiliate/approve',
  async (id: number) => {
    return fetcher({
      url: DELETE_AFFILIATES_INFO_PROPERTY(id),
      method: 'DELETE',
    });
  },
);

const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    delete: (state, action) => {
      state.fields = state.fields.filter((i) => i.id !== action.payload);
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getCustomFields.pending.type, (state): void => {
        state.isLoading = true;
      })
      .addCase(getCustomFields.fulfilled.type, (state, action: PayloadAction<{ data: CustomFieldType[] }>): void => {
        state.isLoading = false;
        state.fields = action.payload?.data;
      })
      .addCase(getCustomFields.rejected.type, (state): void => {
        state.isLoading = false;
        state.fields = EMPTY_ARRAY;
      })
      .addCase(
        getCustomPublicFields.fulfilled.type,
        (state, action: PayloadAction<{ data: CustomFieldType[] }>): void => {
          state.isLoading = false;
          state.fields = action.payload?.data;
        },
      )
      .addCase(getCustomPublicFields.rejected.type, (state): void => {
        state.isLoading = false;
        state.fields = EMPTY_ARRAY;
      })
      .addCase(createField.fulfilled.type, (state, action: PayloadAction<{ data: CustomFieldType }>): void => {
        state.fields = [action.payload?.data, ...state.fields];
      })
      .addCase(updateField.fulfilled.type, (state, action: PayloadAction<{ data: CustomFieldType }>): void => {
        state.fields = state.fields.map((i: CustomFieldType) =>
          i.id === action.payload?.data?.id ? { ...action.payload.data } : i,
        );
      }),
});

const useConfiguration = (): [UseConfigurationStateType, UseConfigurationActionsType] => {
  const state = useAppSelector((storeState: RootState) => storeState.configuration);
  const dispatch = useAppDispatch();

  const actions = {
    get: async (): Promise<void> => {
      await dispatch(getCustomFields());
    },
    getPublic: async (): Promise<void> => {
      await dispatch(getCustomPublicFields());
    },
    createField: async (model: Omit<CustomFieldType, 'id'>): Promise<void> => {
      await dispatch(createField(model));
    },
    updateField: async (model: CustomFieldType): Promise<void> => {
      await dispatch(updateField(model));
    },
    deleteField: async (id: number): Promise<void> => {
      await dispatch(deleteField(id));
      dispatch(configurationSlice.actions.delete(id));
    },
  };

  return [state, actions];
};

export { configurationSlice };

export default useConfiguration;
