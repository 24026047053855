import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useMemo } from 'react';

import { RootState } from 'store/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import fetcher from 'utils/fetcher';
import { GET_PAYMENT_METHODS } from 'api/paths/constants';
import { ApiResponseType, ISaveOptions, PaginatedResponse, IPaymentMethodListDTO } from 'types';
import createSaveReducer from 'utils/createSaveReducer';
import { EMPTY_ARRAY } from 'constant';

type UsePaymentMethodStateType = {
  data: PaginatedResponse<IPaymentMethodListDTO>;
  isLoading: boolean;
};

type UsePaymentMethodActionsType = {
  get: () => Promise<{ data: IPaymentMethodListDTO } | undefined>;
  getLocalById: (id: number) => IPaymentMethodListDTO | undefined;
  save: (options: ISaveOptions<IPaymentMethodListDTO>) => void;
  addPaymentMethod: (data: IPaymentMethodListDTO) => void;
};

const initialState: UsePaymentMethodStateType = {
  isLoading: false,
  data: {
    data: EMPTY_ARRAY,
    count: 0,
  },
};

const getPaymentMethods = createAsyncThunk<ApiResponseType<IPaymentMethodListDTO>>('paymentMethods/get', async () => {
  return fetcher({
    url: GET_PAYMENT_METHODS,
  });
});

const paymentMethodSlice = createSlice({
  name: 'paymentMethod',
  initialState,
  reducers: {
    save: createSaveReducer(),
    addPaymentMethod(state, action: PayloadAction<IPaymentMethodListDTO>): void {
      state.data.data = [action.payload, ...state.data.data];
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getPaymentMethods.pending.type, (state): void => {
        state.isLoading = true;
      })
      .addCase(
        getPaymentMethods.fulfilled.type,
        (state, action: PayloadAction<{ data: PaginatedResponse<IPaymentMethodListDTO> }>) => {
          state.isLoading = false;
          state.data = { ...state.data, data: action.payload?.data.data };
        },
      )
      .addCase(getPaymentMethods.rejected.type, (state): void => {
        state.isLoading = false;
      }),
});

const usePaymentMethod = (): [UsePaymentMethodStateType, UsePaymentMethodActionsType] => {
  const state = useAppSelector((storeState: RootState) => storeState.paymentMethod);
  const dispatch = useAppDispatch();

  const actions = useMemo(
    () => ({
      get: async (): Promise<{ data: IPaymentMethodListDTO } | undefined> => {
        const paymentMethod = await dispatch(getPaymentMethods());
        return paymentMethod.payload as Promise<{ data: IPaymentMethodListDTO } | undefined>;
      },
      getLocalById: (id: number) => state.data.data.find((paymentMethod) => paymentMethod.id === id),
      save: (options: ISaveOptions<IPaymentMethodListDTO>): void => {
        dispatch(paymentMethodSlice.actions.save(options));
      },
      addPaymentMethod: (model: IPaymentMethodListDTO): void => {
        dispatch(paymentMethodSlice.actions.addPaymentMethod(model));
      },
    }),
    [dispatch, state.data.data],
  );

  return [state, actions];
};

export { paymentMethodSlice };

export default usePaymentMethod;
