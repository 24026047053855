import { AffiliatePermissionsEnum } from 'enums/accessTemplates';

import SettingsIcon from 'components/common/icons/bulk/Settings';
import HierarchySquareIcon from 'components/common/icons/bulk/HierarchySquare';
import PresentationChartIcon from 'components/common/icons/bulk/PresentationChart';
import UsersIcon from 'components/common/icons/bulk/Users';
import AffiliatesIcon from 'components/common/icons/bulk/Affiliates';
import ReportsIcon from 'components/common/icons/bulk/Reports';
import ToolsIcon from 'components/common/icons/bulk/Tool';
import CommissionPlanIcon from 'components/common/icons/bulk/CommissionPlan';

export type NavigationRouteType = {
  name: string;
  path?: string;
  icon?: JSX.Element;
  items?: NavigationRouteType[];
  hide?: boolean;
  permissions?: AffiliatePermissionsEnum[];
};

export const navigationRoutes: NavigationRouteType[] = [
  {
    name: 'Partner',
    icon: <HierarchySquareIcon />,
    path: '/partners',
    permissions: [AffiliatePermissionsEnum.SUPER_ADMIN],
  },
  {
    name: 'Payment Methods',
    icon: <CommissionPlanIcon />,
    path: '/payment-methods',
    permissions: [AffiliatePermissionsEnum.SUPER_ADMIN],
  },
  {
    name: 'Dashboard',
    path: '/dashboard',
    icon: <PresentationChartIcon />,
  },
  {
    name: 'Affiliates',
    icon: <AffiliatesIcon />,
    path: '/affiliates/verified',
    permissions: [
      AffiliatePermissionsEnum.SUPER_ADMIN,
      AffiliatePermissionsEnum.ADMIN,
      AffiliatePermissionsEnum.AFFILIATE_USER,
    ],
  },
  {
    name: 'Withdraws',
    icon: <AffiliatesIcon />,
    path: '/withdraws',
    permissions: [AffiliatePermissionsEnum.SUPER_ADMIN, AffiliatePermissionsEnum.ADMIN],
  },
  {
    name: 'Players',
    icon: <UsersIcon />,
    path: '/players',
    permissions: [
      AffiliatePermissionsEnum.SUPER_ADMIN,
      AffiliatePermissionsEnum.ADMIN,
      AffiliatePermissionsEnum.AFFILIATE_USER,
    ],
  },
  {
    name: 'Reports',
    icon: <ReportsIcon />,
    permissions: [
      AffiliatePermissionsEnum.SUPER_ADMIN,
      AffiliatePermissionsEnum.ADMIN,
      AffiliatePermissionsEnum.AFFILIATE_USER,
    ],
    items: [
      {
        name: 'By Category',
        path: '/reports/category',
        permissions: [
          AffiliatePermissionsEnum.SUPER_ADMIN,
          AffiliatePermissionsEnum.ADMIN,
          AffiliatePermissionsEnum.AFFILIATE_USER,
        ],
      },
      // TODO: temporarily hided, open commented code if needed
      // {
      //   name: 'By Provider',
      //   path: '/reports/provider',
      //   permissions: [
      //     AffiliatePermissionsEnum.SUPER_ADMIN,
      //     AffiliatePermissionsEnum.ADMIN,
      //     AffiliatePermissionsEnum.AFFILIATE_USER,
      //   ],
      // },
      {
        name: 'By Affiliate',
        path: '/reports/affiliate',
        permissions: [AffiliatePermissionsEnum.SUPER_ADMIN, AffiliatePermissionsEnum.ADMIN],
      },
      {
        name: 'By Player',
        path: '/reports/player',
        permissions: [
          AffiliatePermissionsEnum.SUPER_ADMIN,
          AffiliatePermissionsEnum.ADMIN,
          AffiliatePermissionsEnum.AFFILIATE_USER,
        ],
      },
    ],
  },
  {
    name: 'Commission Plans',
    path: '/commission-plans',
    icon: <CommissionPlanIcon />,
    permissions: [AffiliatePermissionsEnum.SUPER_ADMIN, AffiliatePermissionsEnum.ADMIN],
  },
  {
    name: 'Tools',
    path: '/tools',
    icon: <ToolsIcon />,
    permissions: [
      AffiliatePermissionsEnum.SUPER_ADMIN,
      AffiliatePermissionsEnum.ADMIN,
      AffiliatePermissionsEnum.AFFILIATE_USER,
    ],
  },
  {
    name: 'Settings',
    icon: <SettingsIcon />,
    permissions: [AffiliatePermissionsEnum.SUPER_ADMIN, AffiliatePermissionsEnum.ADMIN],
    items: [
      {
        name: 'Currencies',
        path: '/settings/currencies',
        permissions: [AffiliatePermissionsEnum.SUPER_ADMIN, AffiliatePermissionsEnum.ADMIN],
      },
      {
        name: 'Payment Methods',
        path: '/settings/payment-methods',
        permissions: [AffiliatePermissionsEnum.SUPER_ADMIN, AffiliatePermissionsEnum.ADMIN],
      },
      {
        name: 'Domains',
        path: '/settings/domains',
        permissions: [AffiliatePermissionsEnum.SUPER_ADMIN, AffiliatePermissionsEnum.ADMIN],
      },
      {
        name: 'Categories',
        path: '/settings/categories',
        permissions: [AffiliatePermissionsEnum.SUPER_ADMIN, AffiliatePermissionsEnum.ADMIN],
      },
      {
        name: 'Branding',
        path: '/settings/branding',
        permissions: [AffiliatePermissionsEnum.SUPER_ADMIN, AffiliatePermissionsEnum.ADMIN],
      },
      {
        name: 'Configurations',
        path: '/settings/configurations',
        permissions: [AffiliatePermissionsEnum.SUPER_ADMIN, AffiliatePermissionsEnum.ADMIN],
      },
    ],
  },
];
