import { useMemo, KeyboardEvent, useEffect, ChangeEventHandler, WheelEventHandler } from 'react';
import { Control, FieldValues, Path, useController, UseFormClearErrors, UseFormSetError } from 'react-hook-form';
import noop from 'lodash/noop';
import { Box, SxProps, TextField, TextFieldProps, Theme, Typography } from '@mui/material';

import { EMPTY_ARRAY } from 'constant';

export interface FormNumberFieldProps<TFieldValues extends FieldValues = FieldValues> {
  label?: string;
  name: Path<TFieldValues>;
  control: Control<TFieldValues>;
  minNumber?: number;
  maxNumber?: number;
  disabled?: boolean;
  digitAfterDot?: number;
  maxNumberError?: string;
  minNumberError?: string;
  withoutDecimal?: boolean;
  maskFormat?: string | boolean;
  setError?: UseFormSetError<TFieldValues>;
  clearErrors?: UseFormClearErrors<TFieldValues>;
}

const sx = {
  label: ({ disabled }: { disabled: boolean }): SxProps<Theme> => ({
    mb: 0.5,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '20px',
    color: disabled ? 'custom.grayscale.scale.50' : undefined,
  }),
};

const FormNumberField = <TFieldValues extends FieldValues = FieldValues>({
  name,
  label,
  control,
  required,
  disabled,
  minNumber,
  maxNumber,
  maskFormat,
  helperText,
  placeholder,
  digitAfterDot,
  withoutDecimal,
  maxNumberError,
  minNumberError,
  fullWidth = true,
  setError = noop,
  clearErrors = noop,
  ...props
}: FormNumberFieldProps<TFieldValues> & TextFieldProps & { name: string }): JSX.Element | null => {
  const {
    fieldState: { error },
    field: { ref, onChange, value = '', ...field },
  } = useController({
    name,
    control,
  });

  const fieldPlaceholder = useMemo(() => placeholder || (label ? `Type ${label}` : ''), [placeholder, label]);

  const formattedNumber = useMemo(() => {
    const numValue = parseFloat(value);

    if (maskFormat && numValue && !Number.isNaN(numValue)) {
      return new Intl.NumberFormat(typeof maskFormat === 'string' ? maskFormat : 'en-US').format(numValue);
    }

    return value;
  }, [value, maskFormat]);

  const transMuteValue = (valueToTransMute: number | string): string | undefined => {
    if (valueToTransMute) {
      const maskedValue = (String(valueToTransMute).match(/[\d|.\+]+/g) || EMPTY_ARRAY) // eslint-disable-line
        .join('')
        .slice(0, 15);
      const parsedValue = parseFloat(maskedValue);

      if (!Number.isNaN(parsedValue) && typeof parsedValue === 'number') {
        return maskedValue;
      } else {
        return;
      }
    } else {
      return String(valueToTransMute);
    }
  };

  useEffect(() => {
    const parsed = value && transMuteValue(value);

    if ((maxNumber || minNumber) && String(parsed)?.length) {
      if (maxNumber && +parsed > maxNumber) {
        setError(name, {
          type: 'number-max',
          message: maxNumberError || 'MaxNumberError',
        });
      } else if (minNumber && +parsed === minNumber) {
        setError(name, {
          type: 'number-min',
          message: minNumberError || 'MinNumberError',
        });
      } else {
        clearErrors(name);
      }
    }
  }, [clearErrors, maxNumber, maxNumberError, minNumber, minNumberError, name, setError, value]);

  if (!name || !control) {
    return null;
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>): void => {
    const { value: inputValue, selectionStart, selectionEnd } = event.target as HTMLInputElement;

    if (event.metaKey || event.ctrlKey) {
      return;
    }

    // Conditions for writing clean numbers
    const isNotNaN = !isNaN(+event.key);
    const isDecimal = !withoutDecimal && event.key === '.' && inputValue?.length >= 1 && !inputValue.includes('.');
    const isAllowedKey = ['Tab', 'Backspace', 'ArrowRight', 'ArrowLeft', 'Delete', 'Shift'].includes(event.key);

    if (
      event.code === 'Space' ||
      (selectionStart === 0 && event.key === '.') ||
      (maskFormat && selectionStart === 0 && inputValue.length) ||
      (maskFormat && !isAllowedKey && inputValue === '0' && event.key !== '.')
    ) {
      event.preventDefault();
      return;
    }

    if (
      selectionStart === selectionEnd &&
      digitAfterDot &&
      (inputValue.split('.')[1] || '')?.length > digitAfterDot - 1
    ) {
      !isAllowedKey && event.preventDefault();
    }

    if (!(isDecimal || isAllowedKey || isNotNaN)) {
      event.preventDefault();
    }
  };

  const handleWheel: WheelEventHandler<HTMLInputElement> = (event) => {
    (event.target as HTMLElement).blur();
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const inputValue = event.target.value;

    onChange(transMuteValue(inputValue));
  };

  return (
    <Box width={fullWidth ? '100%' : 'auto'}>
      <Typography sx={sx.label({ disabled: !!disabled })}>
        {label} {required && '*'}
      </Typography>
      <TextField
        fullWidth={fullWidth}
        {...props}
        {...field}
        ref={ref}
        error={!!error}
        disabled={disabled}
        required={required}
        inputMode="numeric"
        inputProps={{
          pattern: '[0-9]*',
          inputMode: 'numeric',
        }}
        onWheel={handleWheel}
        value={formattedNumber}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder={fieldPlaceholder}
        helperText={error?.message || helperText}
      />
    </Box>
  );
};

export default FormNumberField;
