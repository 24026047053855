import { FC, useState } from 'react';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { FooterSelectMenuTypeEnum } from 'enums/entity';
import { AffiliatePermissionsEnum } from 'enums/accessTemplates';

import { TranslationKey } from 'utils/translate';
import useAuth from 'store/auth';
import usePartners from 'store/partner';
import useToaster from 'hooks/useToaster';

import ProfileDrawerHeader from './ProfileDrawerHeader';
import ProfileDrawerMainMenu from './ProfileDrawerMainMenu';
import SelectListBox from './SelectListBox';
import { ProfileDrawerSelectedListType } from './types';

type Props = {
  open: boolean;
  onClose: () => void;
  onChangePassword: () => void;
  permission?: AffiliatePermissionsEnum;
};

const ProfileDrawer: FC<Props> = ({ open, onClose, onChangePassword, permission }) => {
  const notify = useToaster();
  const [authState, authAction] = useAuth();
  const [partners] = usePartners();
  const [selected, setSelected] = useState(
    authState?.data?.affiliate?.activePartnerId || authState?.data?.affiliate?.partner?.id,
  );
  const [openSelectList, setOpenSelectList] = useState<ProfileDrawerSelectedListType>(null);

  const toggleDrawer = () => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    onClose();
  };

  const handleBack = (): void => {
    setOpenSelectList(null);
  };

  const handleSelectClick = async (id: number): Promise<void> => {
    if (openSelectList === FooterSelectMenuTypeEnum.PARTNER) {
      try {
        await authAction.changePartner(id);
        setSelected(id);
        window.location.reload();
      } catch (error) {
        notify({
          message: (error as Error).message as TranslationKey,
          type: 'error',
        });
      }
      return;
    }
  };

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={toggleDrawer()}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          borderRadius: 0,
        },
      }}
    >
      <Box sx={{ width: 'auto', minHeight: '40vh', maxHeight: '80vh' }} role="presentation">
        <ProfileDrawerHeader
          username={authState?.data?.affiliate?.username}
          handleBack={handleBack}
          openSelectList={!!openSelectList}
        />
        <Divider />
        {openSelectList ? (
          <SelectListBox selectedId={selected || null} list={partners.data} onClick={handleSelectClick} />
        ) : (
          <ProfileDrawerMainMenu
            onClose={onClose}
            onChangePassword={onChangePassword}
            setOpenSelectList={setOpenSelectList}
            permission={permission}
          />
        )}
      </Box>
    </Drawer>
  );
};

export default ProfileDrawer;
