import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useMemo } from 'react';

import { RootState } from 'store/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import fetcher from 'utils/fetcher';
import { GET_AFFILIATES_PLAYER } from 'api/paths/constants';
import { ApiResponseType, ISaveOptions, PaginatedResponse, IPlayerListDTO } from 'types';
import createSaveReducer from 'utils/createSaveReducer';
import { EMPTY_ARRAY } from 'constant';

type UsePlayerStateType = {
  data: PaginatedResponse<IPlayerListDTO>;
  isLoading: boolean;
};

type UsePlayerActionsType = {
  get: (id: number) => Promise<{ data: IPlayerListDTO } | undefined>;
  getLocalById: (id: number) => IPlayerListDTO | undefined;
  save: (options: ISaveOptions<IPlayerListDTO>) => void;
  addPlayer: (data: IPlayerListDTO) => void;
};

const initialState: UsePlayerStateType = {
  isLoading: false,
  data: {
    data: EMPTY_ARRAY,
    count: 0,
  },
};

const getPlayer = createAsyncThunk<ApiResponseType<IPlayerListDTO>, number>('player/get', async (id: number) => {
  return fetcher({
    url: GET_AFFILIATES_PLAYER(id),
  });
});

const playerSlice = createSlice({
  name: 'player',
  initialState,
  reducers: {
    save: createSaveReducer(),
    addPlayer(state, action: PayloadAction<IPlayerListDTO>): void {
      state.data.data = [action.payload, ...state.data.data];
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getPlayer.pending.type, (state): void => {
        state.isLoading = true;
      })
      .addCase(getPlayer.fulfilled.type, (state, action: PayloadAction<{ data: IPlayerListDTO }>) => {
        state.isLoading = false;
        state.data = { ...state.data, data: state.data.data.concat(action.payload?.data) };
      })
      .addCase(getPlayer.rejected.type, (state): void => {
        state.isLoading = false;
      }),
});

const usePlayer = (): [UsePlayerStateType, UsePlayerActionsType] => {
  const state = useAppSelector((storeState: RootState) => storeState.player);
  const dispatch = useAppDispatch();

  const actions = useMemo(
    () => ({
      get: async (id: number): Promise<{ data: IPlayerListDTO } | undefined> => {
        const player = await dispatch(getPlayer(id));
        return player.payload as Promise<{ data: IPlayerListDTO } | undefined>;
      },
      getLocalById: (id: number) => state.data.data.find((player) => player.id === id),
      save: (options: ISaveOptions<IPlayerListDTO>): void => {
        dispatch(playerSlice.actions.save(options));
      },
      addPlayer: (model: IPlayerListDTO): void => {
        dispatch(playerSlice.actions.addPlayer(model));
      },
    }),
    [dispatch, state.data.data],
  );

  return [state, actions];
};

export { playerSlice };

export default usePlayer;
