import { FC } from 'react';
import { Box, IconButton, Stack, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { AffiliatePermissionsEnum } from 'enums/accessTemplates';

import useAuth from 'store/auth';
import useScreen from 'hooks/useScreen';
import { AccessChangePartnerPermissions } from 'utils/accessTemplates';

import UserMenu from './UserMenu';
import WebsitesMenu from './WebsitesMenu';
import SearchBox from './SearchBox';
import PopupMenu from './PopupMenu';
import GetLink from './GetLink';
import sx from './styles';

export interface HeaderNavigationProps {
  setSidebarOpen: (isOpen: boolean) => void;
}

const HeaderNavigation: FC<HeaderNavigationProps> = ({ setSidebarOpen }) => {
  const theme = useTheme();
  const { isMobile } = useScreen();
  const [authState] = useAuth();
  const permission = authState?.data?.affiliate?.permission;

  return (
    <Box sx={isMobile ? sx.sx.root : sx.lg.root}>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          [theme.breakpoints.down('sm')]: {
            flexGrow: 2,
          },
        }}
      >
        {isMobile ? (
          <IconButton onClick={(): void => setSidebarOpen(true)}>
            <MenuIcon />
          </IconButton>
        ) : (
          AccessChangePartnerPermissions.some((r) => r === permission) && <WebsitesMenu />
        )}
        <SearchBox />
      </Stack>
      <Box flex={1} display="flex" alignItems="center" justifyContent="flex-end">
        {!isMobile && (
          <>
            <GetLink permission={permission as AffiliatePermissionsEnum} />
            <PopupMenu permission={permission as AffiliatePermissionsEnum} />
            <UserMenu />
          </>
        )}
      </Box>
    </Box>
  );
};

export default HeaderNavigation;
