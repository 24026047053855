import { ApiResponseType, ChangePasswordFormType } from 'types';
import fetcher from 'utils/fetcher';
import { AuthForgotSchemaType, AuthVerifySchemaType, SetNewPasswordFormType } from 'store/auth';

import { PUT_AFFILIATE_PASSWORD, PUT_SET_NEW_PASSWORD, FORGOT_PASSWORD, PUT_VERIFY_EMAIL } from '../paths/constants';

export const changePassword = (
  model: Partial<ChangePasswordFormType>,
): Promise<ApiResponseType<ChangePasswordFormType>> =>
  fetcher<ChangePasswordFormType, Partial<ChangePasswordFormType>>({
    method: 'PUT',
    url: PUT_AFFILIATE_PASSWORD,
    body: model,
  });

export const setNewPassword = (
  model: Partial<SetNewPasswordFormType>,
): Promise<ApiResponseType<SetNewPasswordFormType>> =>
  fetcher<SetNewPasswordFormType, Partial<SetNewPasswordFormType>>({
    method: 'PUT',
    url: PUT_SET_NEW_PASSWORD,
    body: model,
  });

export const forgotPassword = (model: Partial<AuthForgotSchemaType>): Promise<ApiResponseType<AuthForgotSchemaType>> =>
  fetcher<AuthForgotSchemaType, Partial<AuthForgotSchemaType>>({
    method: 'POST',
    url: FORGOT_PASSWORD,
    body: model,
  });

export const verifyEmail = (model: Partial<AuthVerifySchemaType>): Promise<ApiResponseType<AuthVerifySchemaType>> =>
  fetcher<AuthVerifySchemaType, Partial<AuthVerifySchemaType>>({
    method: 'PUT',
    url: PUT_VERIFY_EMAIL,
    body: model,
  });
