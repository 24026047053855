import { FC, lazy, Suspense } from 'react';

import { translate } from 'utils/translate';

import type { IEditorProps } from './Editor';

const RichTextEditor = lazy(() => import('./Editor'));

const RichTextEditorLazy: FC<IEditorProps> = (props) => (
  <Suspense fallback={<div>{translate('Please be patient')}</div>}>
    <RichTextEditor {...props} />
  </Suspense>
);

export default RichTextEditorLazy;
