import { FC, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { AffiliatePermissionsEnum } from 'enums/accessTemplates';

import AddIcon from 'components/common/icons/bulk/AddIcon';
import { AccessCreatePermissions } from 'utils/accessTemplates';
import TransferIcon from 'components/common/icons/bulk/Transfer';

import CreateAffiliateModal from '../MainModals/CreateAffiliateModal';
import WithdrawModal from '../MainModals/WithdrawModal';

type Props = {
  permission: AffiliatePermissionsEnum;
};

const PopupMenu: FC<Props> = ({ permission }) => {
  const [createOpen, setCreateOpen] = useState(false);
  const [transferOpen, setTransferOpen] = useState(false);

  const openCreatePopup = (): void => setCreateOpen(true);
  const openTransferPopup = (): void => setTransferOpen(true);

  return (
    <>
      <Stack direction="row" spacing={0.5} mx={1}>
        {AccessCreatePermissions.some((r) => r === permission) && (
          <IconButton onClick={openCreatePopup}>
            <AddIcon />
          </IconButton>
        )}
        {permission === AffiliatePermissionsEnum.AFFILIATE_USER && (
          <IconButton onClick={openTransferPopup}>
            <TransferIcon />
          </IconButton>
        )}
      </Stack>
      {transferOpen && permission === AffiliatePermissionsEnum.AFFILIATE_USER && (
        <WithdrawModal onClose={(): void => setTransferOpen(false)} />
      )}
      {createOpen && <CreateAffiliateModal onClose={(): void => setCreateOpen(false)} />}
    </>
  );
};

export default PopupMenu;
