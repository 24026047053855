export interface IFileValidatorsType {
  size?: number;
  type?: string[];
  width?: number;
  height?: number;
  minWidth?: number;
  maxWidth?: number;
  minHeight?: number;
  maxHeight?: number;
}

const validateFile = (file: File, validators: IFileValidatorsType): Promise<{ isValid: boolean; error?: string }> => {
  const { size, type, width, height, minWidth, maxWidth, minHeight, maxHeight } = validators;

  // Type check
  if (type && !type.includes(file.type)) {
    return Promise.resolve({ isValid: false, error: `File type is not supported` });
  }

  // Size check
  if (size && file.size > size) {
    return Promise.resolve({ isValid: false, error: `File size exceeds ${size / 1024 / 1024} MB (${size} bytes)` });
  }

  // Image dimensions check
  if (file.type.startsWith('image/')) {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = function (): void {
        const imgWidth = (this as HTMLImageElement).width;
        const imgHeight = (this as HTMLImageElement).height;

        if (
          (width && imgWidth !== width) ||
          (height && imgHeight !== height) ||
          (minWidth && imgWidth < minWidth) ||
          (maxWidth && imgWidth > maxWidth) ||
          (minHeight && imgHeight < minHeight) ||
          (maxHeight && imgHeight > maxHeight)
        ) {
          const errorMessage = `Image dimensions do not meet requirements`;
          reject({ isValid: false, error: errorMessage });
        } else {
          resolve({ isValid: true });
        }
      };
      image.src = URL.createObjectURL(file);
    });
  }

  return Promise.resolve({ isValid: true });
};

export default validateFile;
