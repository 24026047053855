export const common = {
  white: 'white',
  black: 'black',
};

/**
 * Primary
 * The primary color is your "brand" color, and is used across all interactive elements such as
 * buttons, links, inputs, etc. This color can define the overall feel and can elicit emotion.
 */
export const primary = {
  scale: {
    25: '#F5FAFF',
    50: '#EFF8FF',
    100: '#D1E9FF',
    200: '#B2DDFF',
    300: '#84CAFF',
    400: '#53B1FD',
    500: '#2E90FA',
    600: '#1570EF',
    700: '#175CD3',
    800: '#1849A9',
    900: '#194185',
    1000: '#050B17',
  },
};
/**
 * Neutrals
 * Gray is a neutral color and is the foundation of the color system. Almost everything in UI design —
 * text, form fields, backgrounds, dividers — are usually gray.
 */
export const neutrals = {
  scale: {
    25: '#FCFCFC',
    50: '#FAFAFA',
    70: '#F5F5F5',
    100: '#E5E5E5',
    200: '#D6D6D6',
    300: '#A3A3A3',
    400: '#737373',
    500: '#525252',
    600: '#424242',
    700: '#292929',
    800: '#141414',
    900: '#0F0F0F',
    1000: '#080808',
  },
};

/**
 * Semantic colors
 * Along with primary colors, it's helpful to have a selection of secondary colors to use in components
 * such as pills, alerts and labels. These secondary colors should be used sparingly or as accents,
 * while the primary colors should take precedence.
 */

export const purple = {
  scale: {
    25: '#FAFAFF',
    50: '#F4F3FF',
    100: '#EBE9FE',
    200: '#D9D6FE',
    300: '#BDB4FE',
    400: '#9B8AFB',
    500: '#7A5AF8',
    600: '#6938EF',
    700: '#5925DC',
    800: '#4A1FB8',
    900: '#3E1C96',
  },
};

export const pink = {
  scale: {
    25: '#FEF6FB',
    50: '#FDF2FA',
    100: '#FCE7F6',
    200: '#FCCEEE',
    300: '#FAA7E0',
    400: '#F670C7',
    500: '#EE46BC',
    600: '#DD2590',
    700: '#C11574',
    800: '#9E165F',
    900: '#851651',
  },
};

export const yellow = {
  scale: {
    25: '#FFFCF5',
    50: '#FFFAEB',
    100: '#FEF0C7',
    200: '#FEDF89',
    300: '#FEC84B',
    400: '#FDB022',
    500: '#F79009',
    600: '#DC6803',
    700: '#B54708',
    800: '#93370D',
    900: '#7A2E0E',
  },
};

export const red = {
  scale: {
    25: '#FFFBFA',
    50: '#FEF3F2',
    100: '#FEE4E2',
    200: '#FECDCA',
    300: '#FDA29B',
    400: '#F97066',
    500: '#F04438',
    600: '#D92D20',
    700: '#B42318',
    800: '#912018',
    900: '#7A271A',
  },
};

export const green = {
  scale: {
    25: '#F6FEF9',
    50: '#ECFDF3',
    100: '#D1FADF',
    200: '#A6F4C5',
    300: '#6CE9A6',
    400: '#32D583',
    500: '#12B76A',
    600: '#039855',
    700: '#027A48',
    800: '#05603A',
    900: '#054F31',
  },
};

export const socialMedia = {
  fb: '#1877f2',
  youtube: '#ff0000',
  instagram: '#c32aa3',
  whatsapp: '#25d366',
  telegram: '#0088cc',
  twitter: '#1da1f2',
};
