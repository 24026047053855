import {
  ChangeEventHandler,
  FocusEventHandler,
  ForwardedRef,
  forwardRef,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { Box, Typography, TextField, TextFieldProps, InputAdornment, IconButton } from '@mui/material';

import ShowPasswordIcon from 'components/common/icons/bold/Eye';
import HidePasswordIcon from 'components/common/icons/bold/EyeSlash';
import { TranslationKey, translate } from 'utils/translate';

const sx = {
  counter: {
    fontSize: 10,
    textAlign: 'right',
    color: 'custom.grayscale.scale.50',
  },
};

export type InputProps = TextFieldProps & {
  minValue?: number;
  maxValue?: number;
  label?: TranslationKey;
  maxLength?: number;
};

const Input = forwardRef(function Input(
  {
    type,
    label,
    required,
    disabled,
    minValue,
    maxValue,
    fullWidth,
    multiline,
    placeholder,
    InputProps,
    maxLength,
    onChange,
    ...props
  }: InputProps,
  ref: ForwardedRef<HTMLInputElement>,
): JSX.Element {
  const [isShowPassword, toggleShowPassword] = useReducer((p) => !p, false);
  const [localValue, setLocalValue] = useState<string | number | undefined>(props.value as string | number | undefined);

  useEffect(() => {
    if (String(props.value).length) {
      setLocalValue(props.value as string | number | undefined);
    }
  }, [props.value]);

  const isPasswordField = type === 'password';
  const inputType = isPasswordField && !isShowPassword ? 'password' : type || 'text';
  const inputPlaceholder = placeholder ? placeholder : label ? `Type ${label}` : '';

  const pattern = InputProps?.inputProps?.pattern;

  const handleOnchange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    const { value: nextValue = '' } = event.target;

    if (
      ((maxValue || maxValue === 0) && maxValue >= Number(nextValue)) ||
      ((minValue || minValue === 0) && minValue <= Number(nextValue)) ||
      pattern?.test(nextValue) ||
      nextValue === '' ||
      (!pattern && !minValue && minValue !== 0 && !maxValue && maxValue !== 0)
    ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore, we need to pass the event to the onChange function
      onChange?.({ ...event, target: { ...event.target, value: type === 'number' ? Number(nextValue) : nextValue } });
    }
    (pattern?.test(nextValue) || nextValue === '' || !pattern) && setLocalValue(nextValue);
  };

  const handleOnBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event): void => {
    if (type !== 'number') return props.onBlur?.(event);

    const validatedValue =
      (maxValue || maxValue === 0) &&
      Number(event.target.value) > (maxValue || maxValue === 0 ? maxValue : Number.MAX_SAFE_INTEGER)
        ? maxValue
        : (minValue || minValue === 0) &&
            Number(event.target.value) < (minValue || minValue === 0 ? minValue : Number.MIN_SAFE_INTEGER)
          ? minValue
          : null;
    if (validatedValue || validatedValue === 0) {
      setLocalValue(validatedValue);
    }

    props.onBlur?.(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      validatedValue || validatedValue === 0
        ? {
            ...event,
            target: {
              ...event.target,
              value: validatedValue,
            },
          }
        : event,
    );
  };

  return (
    <Box width={fullWidth ? '100%' : 'auto'}>
      <Typography variant="body2" fontWeight={500} mb={0.5} color="custom.neutrals.scale.500">
        {label && translate(label)} {required && '*'}
      </Typography>
      <TextField
        {...props}
        ref={ref}
        type={isShowPassword ? 'text' : inputType}
        disabled={disabled}
        fullWidth={fullWidth}
        value={localValue}
        onBlur={handleOnBlur}
        multiline={multiline}
        onChange={handleOnchange}
        placeholder={inputPlaceholder}
        InputProps={{
          endAdornment: isPasswordField && (
            <InputAdornment position="end">
              <IconButton edge="end" onClick={toggleShowPassword}>
                {isShowPassword ? <HidePasswordIcon /> : <ShowPasswordIcon />}
              </IconButton>
            </InputAdornment>
          ),
          ...InputProps,
        }}
      />
      {multiline && maxLength && (
        <Typography sx={sx.counter}>
          {(props.value as string)?.length || 0} / {maxLength}
        </Typography>
      )}
    </Box>
  );
});

Input.defaultProps = {
  fullWidth: true,
};

export default Input;
