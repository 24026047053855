export enum PaymentTransactionSourceTypeEnum {
  casino = 1,
  sport = 2,
  payment = 3,
}

export enum TransactionStatus {
  SUCCESS = 1,
  PENDING = 2,
  FAILED = 3,
  IN_USER_REVIEW = 4,
  CANCELLED_BY_USER = 5,
}

export enum TransactionType {
  BET = 'BET',
  WIN = 'WIN',
  LOST = 'LOST',
  DEPOSIT = 'DEPOSIT',
  REFUND = 'REFUND',
  ROLLBACK = 'ROLLBACK',
  WITHDRAW = 'WITHDRAW',
  CORRECTION = 'CORRECTION',
  CANCEL = 'CANCEL',
  BALANCE_ADJUSTMENT = 'ADJUSTMENT',
}

export enum TransactionTypeText {
  BET = 'BET',
  WIN = 'WIN',
  LOST = 'LOST',
  REFUND = 'REFUND',
  ROLLBACK = 'ROLLBACK',
  WITHDRAW = 'WITHDRAW',
  CORRECTION = 'CORRECTION',
  CANCELED = 'CANCELED',
  A_DEPOSIT = '(A) ⬆',
  A_WITHDRAW = '(A) ⬇',
}

export const TransactionStatusEnum = {
  SUCCESS: 'success' as const,
  FAIL: 'error' as const,
  PENDING: 'warning' as const,
};

export enum PaymentTicketStatusEnum {
  COMPLETE = 'COMPLETE',
  PENDING = 'PENDING',
  IN_REVIEW = 'IN_REVIEW',
  CANCELED = 'CANCELED',
}

export enum PaymentTicketStatusTextEnum {
  IN_REVIEW = 'In Review',
  PENDING = 'Pending',
  CANCELED = 'Canceled',
  COMPLETE = 'Complete',
}

export enum BalanceTypeEnum {
  CPA = 'CPA',
  REVENUE = 'REVENUE',
}
