export enum EntityEnum {
  PLAYER = 'player',
  AFFILIATE = 'affiliate',
}

export enum TransferTypeEnum {
  DEPOSIT = 'deposit',
  WITHDRAW = 'withdraw',
}

export enum TrendingActivityEnum {
  UP = 'up',
  DOWN = 'down',
}

export enum FooterNavigationEnum {
  TRANSFER = 'transfer',
  GET_LINK = 'getLink',
  CREATE = 'create',
  NOTIFICATION = 'notification',
  PROFILE = 'profile',
}

export enum AffiliatesAndPlayersEntityType {
  VERIFIED = 'verified',
  PENDING = 'pending',
}

export enum FilterButtonStyleEnum {
  ICON = 'icon',
  BUTTON = 'button',
}

export enum AffiliateBetCategoryTypeEnum {
  SPORT = 'SPORT',
  CASINO = 'CASINO',
}

export enum AffiliateStatusEnum {
  ACTIVE = 'ACTIVE',
  BLOCK = 'BLOCK',
  UNBLOCK = 'UNBLOCK',
  VERIFY = 'VERIFY',
  PENDING = 'PENDING',
}

export enum ActionTypeEnum {
  APPROVE = 'APPROVE',
  BLOCK = 'BLOCK',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  GENERATE_REFERENCE = 'GENERATE_REFERENCE',
  REVOKE_REFERENCE = 'REVOKE_REFERENCE',
}

export enum FooterSelectMenuTypeEnum {
  PARTNER = 'Partner',
}

export enum GlobalModalTypeEnum {
  SIGN_UP = 'signUp',
  SIGN_IN = 'signIn',
  FORGOT_PASSWORD = 'forgotPassword',
  SET_NEW_PASSWORD = 'setNewPassword',
}

export enum Gender {
  MALE = 1,
  FEMALE = 2,
}
