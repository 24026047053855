import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useMemo } from 'react';

import { RootState } from 'store/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import fetcher from 'utils/fetcher';
import { GET_SETTINGS_PAYMENT_METHODS } from 'api/paths/constants';
import { ApiResponseType, ISaveOptions, PaginatedResponse, ISettingsPaymentMethod } from 'types';
import createSaveReducer from 'utils/createSaveReducer';
import { EMPTY_ARRAY } from 'constant';

type UseSettingsPaymentMethodStateType = {
  data: PaginatedResponse<ISettingsPaymentMethod>;
  isLoading: boolean;
};

type UseSettingsPaymentMethodActionsType = {
  get: () => Promise<{ data: ISettingsPaymentMethod } | undefined>;
  getLocalById: (id: number) => ISettingsPaymentMethod | undefined;
  save: (options: ISaveOptions<ISettingsPaymentMethod>) => void;
  addSettingsPaymentMethod: (data: ISettingsPaymentMethod) => void;
};

const initialState: UseSettingsPaymentMethodStateType = {
  isLoading: false,
  data: {
    data: EMPTY_ARRAY,
    count: 0,
  },
};

const getSettingsPaymentMethods = createAsyncThunk<ApiResponseType<ISettingsPaymentMethod>>(
  'settingsPaymentMethods/get',
  async () => {
    return fetcher({
      url: GET_SETTINGS_PAYMENT_METHODS,
    });
  },
);

const settingsPaymentMethodSlice = createSlice({
  name: 'settingsPaymentMethod',
  initialState,
  reducers: {
    save: createSaveReducer(),
    addSettingsPaymentMethod(state, action: PayloadAction<ISettingsPaymentMethod>): void {
      state.data.data = [action.payload, ...state.data.data];
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getSettingsPaymentMethods.pending.type, (state): void => {
        state.isLoading = true;
      })
      .addCase(
        getSettingsPaymentMethods.fulfilled.type,
        (state, action: PayloadAction<{ data: PaginatedResponse<ISettingsPaymentMethod> }>) => {
          state.isLoading = false;
          state.data = { ...state.data, data: action.payload?.data.data };
        },
      )
      .addCase(getSettingsPaymentMethods.rejected.type, (state): void => {
        state.isLoading = false;
      }),
});

const useSettingsPaymentMethod = (): [UseSettingsPaymentMethodStateType, UseSettingsPaymentMethodActionsType] => {
  const state = useAppSelector((storeState: RootState) => storeState.settingsPaymentMethod);
  const dispatch = useAppDispatch();

  const actions = useMemo(
    () => ({
      get: async (): Promise<{ data: ISettingsPaymentMethod } | undefined> => {
        const settingsPaymentMethod = await dispatch(getSettingsPaymentMethods());
        return settingsPaymentMethod.payload as Promise<{ data: ISettingsPaymentMethod } | undefined>;
      },
      getLocalById: (id: number) => state.data.data.find((settingsPaymentMethod) => settingsPaymentMethod.id === id),
      save: (options: ISaveOptions<ISettingsPaymentMethod>): void => {
        dispatch(settingsPaymentMethodSlice.actions.save(options));
      },
      addSettingsPaymentMethod: (model: ISettingsPaymentMethod): void => {
        dispatch(settingsPaymentMethodSlice.actions.addSettingsPaymentMethod(model));
      },
    }),
    [dispatch, state.data.data],
  );

  return [state, actions];
};

export { settingsPaymentMethodSlice };

export default useSettingsPaymentMethod;
