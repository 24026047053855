export const debounce = (callback: (args: unknown) => void, delay: number): ((args: unknown) => void) => {
  let timer: NodeJS.Timeout;

  return (args: unknown): void => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      callback(args);
      clearTimeout(timer);
    }, delay);
  };
};

export const copyValue = (value: string): void => {
  const tempInput = document.createElement('input');
  tempInput.value = value;
  document.body.appendChild(tempInput);
  tempInput.select();
  tempInput.setSelectionRange(0, 99999);
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  navigator.clipboard.writeText(tempInput.value);
  document.execCommand('copy');
  document.body.removeChild(tempInput);
};

export const getLocationPathLastItem = (pathname: string): string => {
  const pathNameArray = pathname.split('/');
  return pathNameArray[pathNameArray.length - 1] as string;
};
