import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { GET_AFFILIATES_INFO_PROPERTY_PUBLIC } from 'api/paths/constants';
import { ApiResponseType, InfoType } from 'types';
import { RootState } from 'store/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import fetcher from 'utils/fetcher';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'constant';

type UseInfoStateType = {
  infoFields: InfoType[];
};

type UseInfoActionsType = {
  get: () => void;
};

const initialState: UseInfoStateType = {
  infoFields: EMPTY_ARRAY,
};

const getInfo = createAsyncThunk<ApiResponseType<InfoType>>(
  GET_AFFILIATES_INFO_PROPERTY_PUBLIC,
  async (body, { rejectWithValue }) => {
    try {
      const response = await fetcher<InfoType>({
        method: 'GET',
        url: GET_AFFILIATES_INFO_PROPERTY_PUBLIC,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const infoSlice = createSlice({
  name: 'info',
  initialState,
  reducers: EMPTY_OBJECT,
  extraReducers: (builder) =>
    builder.addCase(getInfo.fulfilled.type, (state, action: PayloadAction<{ data: InfoType[] }>): void => {
      state.infoFields = action.payload?.data;
    }),
});

const useInfo = (): [UseInfoStateType, UseInfoActionsType] => {
  const state = useAppSelector((storeState: RootState) => storeState.info);
  const dispatch = useAppDispatch();

  const actions = {
    get: async (): Promise<void> => {
      await dispatch(getInfo());
    },
  };

  return [state, actions];
};

export { infoSlice };

export default useInfo;
