import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useMemo } from 'react';

import { RootState } from 'store/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import fetcher from 'utils/fetcher';
import { GET_PAYMENT_METHOD_CUSTOM_FIELDS } from 'api/paths/constants';
import { ApiResponseType, ISaveOptions, PaginatedResponse, IPaymentMethodCustomField } from 'types';
import createSaveReducer from 'utils/createSaveReducer';
import { EMPTY_ARRAY } from 'constant';

type UsePaymentMethodCustomFieldStateType = {
  data: PaginatedResponse<IPaymentMethodCustomField>;
  isLoading: boolean;
};

type UsePaymentMethodCustomFieldActionsType = {
  get: (id: string) => Promise<{ data: IPaymentMethodCustomField } | undefined>;
  getLocalById: (id: number) => IPaymentMethodCustomField | undefined;
  save: (options: ISaveOptions<IPaymentMethodCustomField>) => void;
  addPaymentMethodCustomField: (data: IPaymentMethodCustomField) => void;
};

const initialState: UsePaymentMethodCustomFieldStateType = {
  isLoading: false,
  data: {
    data: EMPTY_ARRAY,
    count: 0,
  },
};

const getPaymentMethodCustomFields = createAsyncThunk<ApiResponseType<IPaymentMethodCustomField>, string>(
  'paymentMethodCustomFields/get',
  async (id: string) => {
    return fetcher({
      url: GET_PAYMENT_METHOD_CUSTOM_FIELDS(id),
    });
  },
);

const paymentMethodCustomFieldSlice = createSlice({
  name: 'paymentMethodCustomField',
  initialState,
  reducers: {
    save: createSaveReducer(),
    addPaymentMethodCustomField(state, action: PayloadAction<IPaymentMethodCustomField>): void {
      state.data.data = [action.payload, ...state.data.data];
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getPaymentMethodCustomFields.pending.type, (state): void => {
        state.isLoading = true;
      })
      .addCase(
        getPaymentMethodCustomFields.fulfilled.type,
        (state, action: PayloadAction<{ data: PaginatedResponse<IPaymentMethodCustomField> }>) => {
          state.isLoading = false;
          state.data = { ...state.data, data: action.payload?.data.data };
        },
      )
      .addCase(getPaymentMethodCustomFields.rejected.type, (state): void => {
        state.isLoading = false;
      }),
});

const usePaymentMethodCustomField = (): [
  UsePaymentMethodCustomFieldStateType,
  UsePaymentMethodCustomFieldActionsType,
] => {
  const state = useAppSelector((storeState: RootState) => storeState.paymentMethodCustomField);
  const dispatch = useAppDispatch();

  const actions = useMemo(
    () => ({
      get: async (id: string): Promise<{ data: IPaymentMethodCustomField } | undefined> => {
        const paymentMethodCustomField = await dispatch(getPaymentMethodCustomFields(id));
        return paymentMethodCustomField.payload as Promise<{ data: IPaymentMethodCustomField } | undefined>;
      },
      getLocalById: (id: number) =>
        state.data.data.find((paymentMethodCustomField) => paymentMethodCustomField.id === id),
      save: (options: ISaveOptions<IPaymentMethodCustomField>): void => {
        dispatch(paymentMethodCustomFieldSlice.actions.save(options));
      },
      addPaymentMethodCustomField: (model: IPaymentMethodCustomField): void => {
        dispatch(paymentMethodCustomFieldSlice.actions.addPaymentMethodCustomField(model));
      },
    }),
    [dispatch, state.data.data],
  );

  return [state, actions];
};

export { paymentMethodCustomFieldSlice };

export default usePaymentMethodCustomField;
