// Affiliate
export const GET_AFFILIATES_VERIFIED = 'affiliates/verified';
export const GET_AFFILIATES_PENDING = 'affiliates/pending';
export const GET_AFFILIATE = (id: number): string => `affiliates/user/${id}`;
export const PUT_AFFILIATE = (id: number): string => `affiliates/user/${id}`;
export const PUT_AFFILIATE_STATUS = (id: number): string => `affiliates/user/status/${id}`;
export const POST_AFFILIATE = `affiliates/user`;
export const GET_AFFILIATE_PLAYERS = (id: string): string => `affiliates/players/own/${id}`;
export const GET_AFFILIATE_PAYMENTS = (id: string): string => `affiliates/transactions/${id}`;
export const CREATE_AFFILIATE_CHANGE_PASSWORD_PUT = (id: number): string => `affiliates/pending/password/${id}`;
export const GET_SUB_AFFILIATES = (id: string): string => `affiliates/sub/${id}`;
export const PUT_AFFILIATE_GENERATE_REFERENCE = (id: number): string => `affiliates/generate-ref/${id}`;
export const PUT_AFFILIATE_REVOKE_REFERENCE = (id: number): string => `affiliates/revoke-ref/${id}`;
export const GET_VERIFIED_SUB_AFFILIATES = 'affiliates/subs';

// Login
export const PUT_ACTIVE_PARTNER = (partnerId: number): string => `affiliates/active-partner/${partnerId}`;
export const LOGIN = 'affiliates/login';
export const LOGOUT = 'affiliates/logout';
export const FORGOT_PASSWORD = 'affiliates/forgot-password';
export const REGISTER = 'affiliates/register';
export const PUT_AFFILIATE_PASSWORD = 'affiliates/password';
export const PUT_SET_NEW_PASSWORD = 'affiliates/set-new-password';
export const PUT_AFFILIATE_RESET_PASSWORD = (id: number | string): string => `affiliates/reset-password/${id}`;
export const PUT_VERIFY_EMAIL = `affiliates/verify-email`;

// Branding
export const GET_AFFILIATES_BRANDING = 'public/affiliates/branding';
export const PUT_AFFILIATES_BRANDING = (id: number): string => `affiliates/branding/${id}`;

export const GET_SEARCH = 'affiliates/search';
export const PUT_CURRENCY_ENABLE = 'affiliates/currencies/enable';
export const ALL_COUNTRIES_GET = 'countries/all';

// Currency
export const GET_AFFILIATES_SYSTEM_CURRENCIES = 'affiliates/system/currencies';
export const GET_AFFILIATES_PARTNER_CURRENCIES = 'affiliates/partner/currencies';
export const GET_AFFILIATES_PUBLIC_CURRENCIES = 'public/affiliates/currencies';
export const POST_AFFILIATES_CURRENCY = 'affiliates/currency';
export const PUT_AFFILIATES_CURRENCY = (id: number): string => `affiliates/currency/${id}`;

// Domain
export const GET_AFFILIATES_PARTNER_ALL_DOMAINS = 'affiliates/partner/domains';
export const GET_AFFILIATES_PARTNER_ACTIVE_DOMAINS = 'affiliates/domains';
export const POST_AFFILIATES_DOMAINS = 'affiliates/domain';
export const PUT_AFFILIATES_DOMAIN = (id: number): string => `affiliates/domain/${id}`;
export const DELETE_AFFILIATES_DOMAIN = (id: number): string => `affiliates/domain/${id}`;

// Category
export const GET_AFFILIATES_PARTNER_ALL_CATEGORIES = 'affiliates/partner/categories';
export const POST_AFFILIATES_CATEGORY = 'affiliates/category';
export const PUT_AFFILIATES_CATEGORY = (id: number): string => `affiliates/category/${id}`;
export const DELETE_AFFILIATES_CATEGORY = (id: number): string => `affiliates/category/${id}`;

// Info property
export const GET_AFFILIATES_INFO_PROPERTY = 'affiliates/info-properties';
export const GET_AFFILIATES_INFO_PROPERTY_PUBLIC = 'affiliates/info-properties/public';
export const POST_AFFILIATES_INFO_PROPERTY = 'affiliates/info-property';
export const PUT_AFFILIATES_INFO_PROPERTY = (id: number): string => `affiliates/info-property/${id}`;
export const DELETE_AFFILIATES_INFO_PROPERTY = (id: number): string => `affiliates/info-property/${id}`;

// Partner
export const GET_AFFILIATES_PARTNERS = 'affiliates/partners';
export const GET_AFFILIATES_PARTNER = (id: number): string => `affiliates/partners/${id}`;
export const POST_AFFILIATES_PARTNER = 'affiliates/partners';
export const PUT_AFFILIATES_PARTNER = (id: number): string => `affiliates/partners/${id}`;

export const PUT_CHANGE_PARTNER = 'affiliates/active-partner/:partnerId';
export const PUT_PARTNER_CURRENCY_AVAILABLE = 'affiliates/currencies/available';
export const CREATE_CHANGE_PARTNER_PUT = (id: number): string => `affiliates/active-partner/${id}`;
export const CREATE_PARTNER_CURRENCIES_GET = (id: number | string): string => `affiliates/currencies/${id}`;

// Players
export const GET_AFFILIATES_PLAYERS = 'affiliates/players';
export const GET_AFFILIATES_PLAYER = (id: number): string => `affiliates/players/${id}`;
export const GET_PLAYER_TRANSACTIONS_BET = (id: string): string => `affiliates/player/transactions/bet/${id}`;
export const GET_PLAYER_TRANSACTIONS_PAYMENT = (id: string): string => `affiliates/player/transactions/payment/${id}`;

export const POST_PLAYER = 'affiliates/player';
export const PLAYERS_GROUP_POST = 'riskGroup';
export const CREATE_PLAYER_GET = (id: number): string => `affiliates/player/info/${id}`;
export const CREATE_PLAYER_DEPOSIT_PUT = (id: number | string): string => `affiliates/player/deposit/${id}`;
export const CREATE_PLAYER_WITHDRAW_PUT = (id: number | string): string => `affiliates/player/withdraw/${id}`;
export const CREATE_PUT_PLAYER_WITHDRAW = (id: number): string => `affiliates/player/withdraw/${id}`;
export const CREATE_PUT_PLAYER_DEPOSIT = (id: number): string => `affiliates/player/deposit/${id}`;
export const CREATE_PLAYER_PUT = (id: number): string => `affiliates/players/${id}`;

// Report
export const GET_REPORTS_BY_AFFILIATE = 'affiliates/report/affiliate';
export const GET_REPORTS_BY_CATEGORY = 'affiliates/report/category';
export const GET_REPORTS_BY_PROVIDER = 'affiliates/report/provider';
export const GET_REPORTS_BY_PLAYER = 'affiliates/report/player';

// Dashboard
export const GET_ACTIVITY_CARDS = (): string => `affiliates/dashboard/overview`;
export const GET_ACTIVITY_CARDS_PERIODICAL = (code: string): string =>
  `affiliates/dashboard/overview/periodical/${code}`;

//Access Template
export const PLAYER_DASHBOARD_GET = (id: number): string => `affiliates/dashboard/player/${id}`;
export const AFFILIATE_DASHBOARD_GET = (id: number): string => `affiliates/dashboard/affiliate/${id}`;

// Commission Plans
export const GET_COMMISSION_PLANS = `affiliates/commission-plans`;
export const GET_COMMISSION_PLAN = (id: number): string => `affiliates/commission-plans/${id}`;
export const GET_COMMISSION_PLAN_OWN = `affiliates/commission-plans/own`;
export const GET_COMMISSION_PLAN_BY_CURRENCY = (code: string): string => `affiliates/commission-plans/currency/${code}`;
export const CREATE_COMMISSION_PLANS_POST = `affiliates/commission-plan`;
export const UPDATE_COMMISSION_PLANS_POST = `affiliates/commission-plan`;
export const DELETE_COMMISSION_PLAN = (id: number): string => `affiliates/commission-plan/${id}`;

// Payment methods
export const GET_PAYMENT_METHODS = 'affiliates/system/payment-methods';
export const GET_PAYMENT_METHOD = (id: number): string => `affiliates/system/payment-methods/${id}`;
export const PUP_PAYMENT_METHOD = (id: number): string => `affiliates/system/payment-methods/${id}`;
export const CREATE_PAYMENT_METHOD_POST = `affiliates/system/payment-methods`;
export const DELETE_PAYMENT_METHOD = (id: number): string => `affiliates/system/payment-methods/${id}`;
export const GET_PAYMENT_METHOD_CURRENCIES = (id: string): string =>
  `affiliates/system/payment-methods/currencies/${id}`;
export const DELETE_PAYMENT_METHOD_CURRENCY = (id: number): string =>
  `affiliates/system/payment-methods/currencies/${id}`;
export const POST_PAYMENT_METHOD_CURRENCY = (id: string): string =>
  `affiliates/system/payment-methods/currencies/${id}`;
export const GET_PARTNER_PAYMENT_METHODS = (partnerId: string): string =>
  `affiliates/partner/payment-methods/system/${partnerId}`;
export const POST_PARTNER_PAYMENT_METHODS = 'affiliates/partner/payment-methods';
export const GET_SETTINGS_PAYMENT_METHODS = 'affiliates/partner/payment-methods';
export const PUT_PARTNER_PAYMENT_METHOD_AVAILABLE = (id: number): string =>
  `affiliates/partner/payment-methods/available/${id}`;
export const PUT_SETTING_PAYMENT_METHOD_ENABLE = (id: number): string =>
  `affiliates/partner/payment-methods/enable/${id}`;
export const GET_PAYMENT_METHOD_CUSTOM_FIELDS = (id: string): string =>
  `affiliates/payment-method/info-properties/${id}`;
export const POST_PAYMENT_METHOD_CUSTOM_FIELD = `affiliates/payment-method/info-properties`;
export const PUT_SETTING_PAYMENT_METHOD_CUSTOM_FIELD = (id: number): string =>
  `affiliates/payment-method/info-properties/${id}`;
export const GET_SETTINGS_PAYMENT_METHOD_CURRENCIES = (id: string): string => `affiliates/partner/payment-limits/${id}`;
export const POST_SETTINGS_PAYMENT_METHODS_CURRENCY = 'affiliates/partner/payment-limits';
export const PUT_SETTING_PAYMENT_METHOD_CURRENCY = (id: number): string => `affiliates/partner/payment-limits/${id}`;
export const GET_SETTINGS_PAYMENT_METHODS_ENABLED = 'affiliates/partner/enabled/payment-methods';
export const POST_WITHDRAW = `affiliates/tickets`;

// Payment
export const GET_IN_REVIEW_PAYMENTS = 'affiliates/tickets/in-progress';
export const GET_COMPLETED_PAYMENTS = 'affiliates/tickets/final';
export const UPDATE_IN_REVIEW_PAYMENT = (ticketId: number): string => `affiliates/tickets/in-review/${ticketId}`;
export const UPDATE_PENDING_PAYMENT = (ticketId: number): string => `affiliates/tickets/pending/${ticketId}`;

//Branding
export const GET_BRANDING = 'public/affiliates/branding';
export const PUT_BRANDING = (id: number): string => `affiliates/branding/${id}`;
