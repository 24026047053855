import { FC, Fragment, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Collapse, Stack, Typography } from '@mui/material';
import { AffiliatePermissionsEnum } from 'enums/accessTemplates';

import { navigationRoutes, NavigationRouteType } from 'configs/navigation';
import useScreen from 'hooks/useScreen';
import DotIcon from 'components/common/icons/Dot';
import ArrowUpIcon from 'components/common/icons/ArrowUp';
import ArrowDownIcon from 'components/common/icons/ArrowDown';

import sx from './styles';

type Props = {
  handleDrawerClose: () => void;
  existPermission: AffiliatePermissionsEnum;
};

const SidebarMenu: FC<Props> = ({ handleDrawerClose, existPermission }): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isMobile } = useScreen();
  const [opened, setOpened] = useState<string>('');

  const handleMenuItemClick = (item: NavigationRouteType): void => {
    if (item?.items) {
      setOpened((prev) => (prev === item.name ? '' : item.name));
    } else {
      navigate(item.path as string);
      isMobile && handleDrawerClose();
    }
  };

  const renderMenu = (items: NavigationRouteType[], childItems?: boolean): JSX.Element => {
    return (
      <Stack gap={0.75}>
        {items
          .filter((i) => !i.hide && (!i.permissions || i.permissions.some((r) => r === existPermission)))
          .map((item) => {
            const isOpened = opened === item.name;
            const locationPathFirstSegment = location.pathname.split('/').at(1);
            const menuPathFirstSegment = item.path && item.path.split('/').at(1);
            const isSelected = childItems
              ? String(location.pathname).includes(item.path as string)
              : locationPathFirstSegment === menuPathFirstSegment;

            return (
              <Fragment key={`${item.name}-${item.path || ''}`}>
                <Box
                  onClick={(): void => handleMenuItemClick(item)}
                  sx={sx.menuItem({ selected: isOpened || isSelected })}
                >
                  {item.icon ? item.icon : <DotIcon />}
                  <Typography variant="body1" ml={1} flex={1}>
                    {item.name}
                  </Typography>
                  {!!item?.items?.length && <>{isOpened ? <ArrowUpIcon /> : <ArrowDownIcon />}</>}
                </Box>
                {!!item?.items?.length && <Collapse in={isOpened}>{renderMenu(item?.items, true)}</Collapse>}
              </Fragment>
            );
          })}
      </Stack>
    );
  };

  return <Box sx={sx.content}>{renderMenu(navigationRoutes)}</Box>;
};

export default SidebarMenu;
