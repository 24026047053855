import { FC, ReactNode } from 'react';
import { FormEvent, FormEventHandler, useCallback } from 'react';
import Stack, { StackProps } from '@mui/material/Stack';

interface FormProps {
  onSubmit?: FormEventHandler;
  children?: ReactNode;
}

const Form: FC<FormProps & StackProps> = ({ onSubmit, ...props }) => {
  const handleSubmit = useCallback(
    (event: FormEvent): void => {
      event.preventDefault();
      event.stopPropagation();
      onSubmit && onSubmit(event);
    },
    [onSubmit],
  );

  return <Stack component="form" noValidate autoComplete="off" onSubmit={handleSubmit} {...props} />;
};

export { default as FormInput } from './Input';
export { default as FormSelect } from './Select';
export { default as FormUploader } from './Uploader';
export { default as FormCheckbox } from './Checkbox';
export { default as FormDatePicker } from './DatePicker';
export { default as FormNumberInput } from './NumberField';
export { default as FormRichTextEditor } from './RichTextEditor';
export { default as FormDateRangePicker } from './DateRangeField';
export { default as FormPhoneFiled } from './PhoneField';
export { default as Autocomplete } from './Autocomplete';
export { default as FormNumberInputWithMenu } from './NumberInputWithMenu';

export default Form;
