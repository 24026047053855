import { useMemo } from 'react';
import { Control, FieldValues, Path, Controller } from 'react-hook-form';
import { Box, SxProps, TextFieldProps, Theme, Typography } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';

import { TranslationKey } from 'utils/translate';

interface FormInputProps<T extends FieldValues> {
  label?: TranslationKey;
  control: Control<T>;
}

const sx = {
  label: ({ disabled }: { disabled: boolean }): SxProps<Theme> => ({
    mb: 0.5,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '20px',
    color: disabled ? 'custom.grayscale.scale.50' : undefined,
  }),
};

const PhoneField = <T extends FieldValues>({
  name,
  control,
  required,
  disabled,
  label,
  placeholder,
  helperText,
  fullWidth = true,
  ...props
}: TextFieldProps & FormInputProps<T>): JSX.Element | null => {
  const fieldPlaceholder = useMemo(() => placeholder || (label ? `Type ${label}` : ''), [placeholder, label]);

  const checkPhoneValid = (value: string): boolean => (value ? matchIsValidTel(value) : true);

  if (!name || !control) {
    return null;
  }

  return (
    <Box width={fullWidth ? '100%' : 'auto'}>
      <Typography sx={sx.label({ disabled: !!disabled })}>
        {label} {required && '*'}
      </Typography>
      <Controller
        name={name as Path<T>}
        control={control}
        rules={{ validate: checkPhoneValid }}
        render={({ field, fieldState }): JSX.Element => (
          <MuiTelInput
            {...props}
            {...field}
            contentEditable={undefined}
            required={required}
            fullWidth={fullWidth}
            disabled={disabled}
            error={!!fieldState.error}
            placeholder={fieldPlaceholder}
            helperText={fieldState.error?.message || helperText}
            unknownFlagElement={<PhoneIcon />}
          />
        )}
      />
    </Box>
  );
};

export default PhoneField;
