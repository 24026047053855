import { Control, FieldPath, FieldValues, useController } from 'react-hook-form';
import { Box } from '@mui/material';

import { TranslationKey } from 'utils/translate';
import RichTextEditor from 'components/common/RichTextEditor';

interface FormRichTextEditor<TFieldValues extends FieldValues = FieldValues> {
  name: FieldPath<TFieldValues>;
  control?: Control<TFieldValues>;
  label?: TranslationKey;
  required?: boolean;
  disabled?: boolean;
}

function FormRichTextEditor<TFieldValues extends FieldValues = FieldValues>({
  name,
  label,
  control,
  required,
  disabled,
}: FormRichTextEditor<TFieldValues>): JSX.Element | null {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  if (!name || !control) {
    return null;
  }

  const handleChange = (content: string): void => {
    onChange(content);
  };

  return (
    <Box
      onBlur={(event): void => {
        event.stopPropagation();
        event.preventDefault();
      }}
    >
      <RichTextEditor
        label={label}
        required={required}
        hideControlButtons
        isEditable={!disabled}
        value={value}
        error={error?.message}
        onSave={handleChange}
      />
    </Box>
  );
}

export default FormRichTextEditor;
