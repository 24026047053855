import { lazy } from 'react';

const routes = [
  {
    path: '*',
    element: lazy(() => import('./Landing')),
  },
];

export default routes;
