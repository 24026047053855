import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useMemo } from 'react';
import { AffiliateStatusEnum } from 'enums/entity';

import { RootState } from 'store/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import fetcher from 'utils/fetcher';
import {
  GET_AFFILIATE,
  PUT_AFFILIATE_STATUS,
  PUT_AFFILIATE_RESET_PASSWORD,
  PUT_AFFILIATE_GENERATE_REFERENCE,
  PUT_AFFILIATE_REVOKE_REFERENCE,
} from 'api/paths/constants';
import { AffiliateApproveType, ApiResponseType, ISaveOptions, PaginatedResponse, IAffiliateListDTO } from 'types';
import createSaveReducer from 'utils/createSaveReducer';
import { EMPTY_ARRAY } from 'constant';

type UseAffiliateStateType = {
  data: PaginatedResponse<IAffiliateListDTO>;
  isLoading: boolean;
};

type UseAffiliateActionsType = {
  get: (id: number) => Promise<{ data: IAffiliateListDTO } | undefined>;
  getLocalById: (id: number) => IAffiliateListDTO | undefined;
  save: (options: ISaveOptions<IAffiliateListDTO>) => void;
  addAffiliate: (data: IAffiliateListDTO) => void;
  removeAffiliate: (id: number) => void;
  approve: (data: AffiliateApproveType & { id: number }) => Promise<void>;
  block: ({ status, id }: { id: number; status: AffiliateStatusEnum }) => Promise<void>;
  generateReference: (id: number) => Promise<void>;
  revokeReference: (id: number) => Promise<void>;
  resetPassword: (id: number) => Promise<void>;
};

const initialState: UseAffiliateStateType = {
  isLoading: false,
  data: {
    data: EMPTY_ARRAY,
    count: 0,
  },
};

const getAffiliate = createAsyncThunk<ApiResponseType<IAffiliateListDTO>, number>(
  'affiliate/get',
  async (id: number) => {
    return fetcher({
      url: GET_AFFILIATE(id),
    });
  },
);

const block = createAsyncThunk<ApiResponseType<IAffiliateListDTO>, { id: number; status: AffiliateStatusEnum }>(
  'affiliate/block',
  async ({ id, status }: { id: number; status: AffiliateStatusEnum }) => {
    return fetcher({
      url: PUT_AFFILIATE_STATUS(id),
      method: 'PUT',
      body: {
        action: status,
      },
    });
  },
);

const generateReference = createAsyncThunk<ApiResponseType<IAffiliateListDTO>, number>(
  'affiliate/generateReference',
  async (id: number) => {
    return fetcher({
      url: PUT_AFFILIATE_GENERATE_REFERENCE(id),
      method: 'PUT',
    });
  },
);

const revokeReference = createAsyncThunk<ApiResponseType<IAffiliateListDTO>, number>(
  'affiliate/revokeReference',
  async (id: number) => {
    return fetcher({
      url: PUT_AFFILIATE_REVOKE_REFERENCE(id),
      method: 'PUT',
    });
  },
);

const resetPassword = createAsyncThunk<ApiResponseType<IAffiliateListDTO>, number>(
  'affiliate/resetPassword',
  async (id: number) => {
    return fetcher({
      url: PUT_AFFILIATE_RESET_PASSWORD(id),
      method: 'PUT',
    });
  },
);

const approve = createAsyncThunk<ApiResponseType<IAffiliateListDTO>, AffiliateApproveType & { id: number }>(
  'affiliate/approve',
  async (body: AffiliateApproveType & { id: number }) => {
    const { id, ...rest } = body;
    return fetcher({
      url: PUT_AFFILIATE_STATUS(id),
      method: 'PUT',
      body: { ...rest, action: AffiliateStatusEnum.VERIFY },
    });
  },
);

const affiliateSlice = createSlice({
  name: 'affiliate',
  initialState,
  reducers: {
    save: createSaveReducer(),
    addAffiliate(state, action: PayloadAction<IAffiliateListDTO>): void {
      state.data.data = [action.payload, ...state.data.data];
    },
    removeAffiliate(state, action: PayloadAction<number>): void {
      state.data.data = state.data.data.filter((i: IAffiliateListDTO) => i.id !== action.payload);
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getAffiliate.pending.type, (state): void => {
        state.isLoading = true;
      })
      .addCase(getAffiliate.fulfilled.type, (state, action: PayloadAction<{ data: IAffiliateListDTO }>) => {
        state.isLoading = false;
        state.data = { ...state.data, data: state.data.data.concat(action.payload?.data) };
      })
      .addCase(getAffiliate.rejected.type, (state): void => {
        state.isLoading = false;
      })
      .addCase(block.fulfilled.type, (state, action: PayloadAction<{ data: IAffiliateListDTO }>): void => {
        state.data.data = state.data.data
          .map((i: IAffiliateListDTO) => (i.id === action.payload?.data?.id ? { ...i, ...action.payload.data } : i))
          .filter(
            (i) =>
              i.id !== action.payload?.data?.id ||
              (i.id === action.payload?.data?.id && i.status !== AffiliateStatusEnum.PENDING),
          );
      })
      .addCase(generateReference.fulfilled.type, (state, action: PayloadAction<{ data: IAffiliateListDTO }>): void => {
        state.data.data = state.data.data.map((item: IAffiliateListDTO) =>
          item.id === action.payload?.data?.id
            ? { ...item, affiliateInvitationReference: action.payload.data.affiliateInvitationReference }
            : item,
        );
      })
      .addCase(revokeReference.fulfilled.type, (state, action: PayloadAction<{ data: IAffiliateListDTO }>): void => {
        state.data.data = state.data.data.map((item: IAffiliateListDTO) =>
          item.id === action.payload?.data?.id ? { ...item, affiliateInvitationReference: '' } : item,
        );
      })
      .addCase(approve.fulfilled.type, (state, action: PayloadAction<{ data: IAffiliateListDTO }>): void => {
        state.data.data = state.data.data.filter((i: IAffiliateListDTO) => i.id !== action.payload?.data?.id);
      }),
});

const useAffiliate = (): [UseAffiliateStateType, UseAffiliateActionsType] => {
  const state = useAppSelector((storeState: RootState) => storeState.affiliate);
  const dispatch = useAppDispatch();

  const actions = useMemo(
    () => ({
      get: async (id: number): Promise<{ data: IAffiliateListDTO } | undefined> => {
        const affiliate = await dispatch(getAffiliate(id));
        return affiliate.payload as Promise<{ data: IAffiliateListDTO } | undefined>;
      },
      getLocalById: (id: number) => state.data.data.find((affiliate) => affiliate.id === id),
      save: (options: ISaveOptions<IAffiliateListDTO>): void => {
        dispatch(affiliateSlice.actions.save(options));
      },
      addAffiliate: (model: IAffiliateListDTO): void => {
        dispatch(affiliateSlice.actions.addAffiliate(model));
      },
      removeAffiliate: (id: number): void => {
        dispatch(affiliateSlice.actions.removeAffiliate(id));
      },
      approve: async (model: AffiliateApproveType & { id: number }): Promise<void> => {
        await dispatch(approve(model));
      },
      block: async ({ id, status }: { id: number; status: AffiliateStatusEnum }): Promise<void> => {
        await dispatch(block({ id, status }));
      },
      resetPassword: async (id: number): Promise<void> => {
        await dispatch(resetPassword(id));
      },
      generateReference: async (id: number): Promise<void> => {
        await dispatch(generateReference(id));
      },
      revokeReference: async (id: number): Promise<void> => {
        await dispatch(revokeReference(id));
      },
    }),
    [dispatch, state.data.data],
  );

  return [state, actions];
};

export { affiliateSlice };

export default useAffiliate;
