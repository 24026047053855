import { SystemStyleObject } from '@mui/system';

const sx = {
  root: {
    p: 1.5,
    borderWidth: 1,
    borderRadius: 1.5,
    borderStyle: 'solid',
    borderColor: 'custom.neutrals.scale.100',
    display: 'flex',
    '&:hover': {
      '[role="image-cover"]': {
        bgcolor: 'custom.primary.scale.25',
        borderColor: 'custom.primary.scale.300',
      },
      '[role="overlay"]': {
        opacity: 1,
      },
    },
  },
  imageCover: ({ src }: { src: string }): SystemStyleObject => ({
    width: '100%',
    height: '100%',
    borderWidth: 1,
    borderRadius: 1,
    borderStyle: 'dashed',
    borderColor: 'custom.neutrals.scale.100',
    boxShadow: '0px 1px 2px 0px rgba(15, 15, 15, 0.05)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: '0.3s',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: src && `url(${src})`,
    position: 'relative',
  }),
  overlay: {
    opacity: 0,
    width: '100%',
    height: '100%',
    position: 'absolute',
    backdropFilter: 'blur(2px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: '0.3s',
    '& button, & button:hover': {
      width: 32,
      height: 32,
      border: '2px solid rgba(229, 229, 229, 0.20)',
      background: 'rgba(255, 255, 255, 0.40)',
      '& svg': {
        color: 'white',
        fontSize: 18,
      },
    },
  },
  imageCoverIcon: {
    width: 32,
    height: 32,
    borderRadius: 1,
    bgcolor: 'custom.primary.scale.100',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  uploadButton: {
    '&, &:hover': {
      bgcolor: 'custom.primary.scale.50',
      color: 'custom.primary.scale.500',
      boxShadow: '0px 1px 2px 0px rgba(15, 15, 15, 0.05)',
    },
  },
};

export default sx;
