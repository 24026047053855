import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useMemo } from 'react';

import { RootState } from 'store/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import fetcher from 'utils/fetcher';
import { GET_PARTNER_PAYMENT_METHODS } from 'api/paths/constants';
import { ApiResponseType, ISaveOptions, PaginatedResponse, IPaymentMethodPartner } from 'types';
import createSaveReducer from 'utils/createSaveReducer';
import { EMPTY_ARRAY } from 'constant';

type UsePartnerPaymentMethodStateType = {
  data: PaginatedResponse<IPaymentMethodPartner>;
  isLoading: boolean;
};

type UsePartnerPaymentMethodActionsType = {
  get: (id: string) => Promise<{ data: IPaymentMethodPartner } | undefined>;
  getLocalById: (id: number) => IPaymentMethodPartner | undefined;
  save: (options: ISaveOptions<IPaymentMethodPartner>) => void;
  addPartnerPaymentMethod: (data: IPaymentMethodPartner) => void;
};

const initialState: UsePartnerPaymentMethodStateType = {
  isLoading: false,
  data: {
    data: EMPTY_ARRAY,
    count: 0,
  },
};

const getPartnerPaymentMethods = createAsyncThunk<ApiResponseType<IPaymentMethodPartner>, string>(
  'partnerPaymentMethods/get',
  async (id: string) => {
    return fetcher({
      url: GET_PARTNER_PAYMENT_METHODS(id),
    });
  },
);

const partnerPaymentMethodSlice = createSlice({
  name: 'partnerPaymentMethod',
  initialState,
  reducers: {
    save: createSaveReducer(),
    addPartnerPaymentMethod(state, action: PayloadAction<IPaymentMethodPartner>): void {
      state.data.data = [action.payload, ...state.data.data];
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getPartnerPaymentMethods.pending.type, (state): void => {
        state.isLoading = true;
      })
      .addCase(
        getPartnerPaymentMethods.fulfilled.type,
        (state, action: PayloadAction<{ data: PaginatedResponse<IPaymentMethodPartner> }>) => {
          state.isLoading = false;
          state.data = { ...state.data, data: action.payload?.data.data };
        },
      )
      .addCase(getPartnerPaymentMethods.rejected.type, (state): void => {
        state.isLoading = false;
      }),
});

const usePartnerPaymentMethod = (): [UsePartnerPaymentMethodStateType, UsePartnerPaymentMethodActionsType] => {
  const state = useAppSelector((storeState: RootState) => storeState.partnerPaymentMethod);
  const dispatch = useAppDispatch();

  const actions = useMemo(
    () => ({
      get: async (id: string): Promise<{ data: IPaymentMethodPartner } | undefined> => {
        const partnerPaymentMethod = await dispatch(getPartnerPaymentMethods(id));
        return partnerPaymentMethod.payload as Promise<{ data: IPaymentMethodPartner } | undefined>;
      },
      getLocalById: (id: number) => state.data.data.find((partnerPaymentMethod) => partnerPaymentMethod.id === id),
      save: (options: ISaveOptions<IPaymentMethodPartner>): void => {
        dispatch(partnerPaymentMethodSlice.actions.save(options));
      },
      addPartnerPaymentMethod: (model: IPaymentMethodPartner): void => {
        dispatch(partnerPaymentMethodSlice.actions.addPartnerPaymentMethod(model));
      },
    }),
    [dispatch, state.data.data],
  );

  return [state, actions];
};

export { partnerPaymentMethodSlice };

export default usePartnerPaymentMethod;
