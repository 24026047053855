import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useMemo } from 'react';

import { RootState } from 'store/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import fetcher from 'utils/fetcher';
import { GET_PAYMENT_METHOD_CURRENCIES } from 'api/paths/constants';
import { ApiResponseType, ISaveOptions, PaginatedResponse, IPaymentMethodCurrency } from 'types';
import createSaveReducer from 'utils/createSaveReducer';
import { EMPTY_ARRAY } from 'constant';

type UsePaymentMethodCurrencyStateType = {
  data: PaginatedResponse<IPaymentMethodCurrency>;
  isLoading: boolean;
};

type UsePaymentMethodCurrencyActionsType = {
  get: (id: number) => Promise<{ data: IPaymentMethodCurrency } | undefined>;
  getLocalById: (id: number) => IPaymentMethodCurrency | undefined;
  save: (options: ISaveOptions<IPaymentMethodCurrency>) => void;
  addPaymentMethodCurrency: (data: IPaymentMethodCurrency) => void;
};

const initialState: UsePaymentMethodCurrencyStateType = {
  isLoading: false,
  data: {
    data: EMPTY_ARRAY,
    count: 0,
  },
};

const getPaymentMethodCurrencies = createAsyncThunk<ApiResponseType<IPaymentMethodCurrency>, number>(
  'paymentMethodCurrencies/get',
  async (id: number) => {
    return fetcher({
      url: GET_PAYMENT_METHOD_CURRENCIES(String(id)),
    });
  },
);

const paymentMethodCurrencySlice = createSlice({
  name: 'paymentMethodCurrency',
  initialState,
  reducers: {
    save: createSaveReducer(),
    addPaymentMethodCurrency(state, action: PayloadAction<IPaymentMethodCurrency>): void {
      state.data.data = [action.payload, ...state.data.data];
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getPaymentMethodCurrencies.pending.type, (state): void => {
        state.isLoading = true;
      })
      .addCase(
        getPaymentMethodCurrencies.fulfilled.type,
        (state, action: PayloadAction<{ data: PaginatedResponse<IPaymentMethodCurrency> }>) => {
          state.isLoading = false;
          state.data = { ...state.data, data: action.payload?.data.data };
        },
      )
      .addCase(getPaymentMethodCurrencies.rejected.type, (state): void => {
        state.isLoading = false;
      }),
});

const usePaymentMethodCurrency = (): [UsePaymentMethodCurrencyStateType, UsePaymentMethodCurrencyActionsType] => {
  const state = useAppSelector((storeState: RootState) => storeState.paymentMethodCurrency);
  const dispatch = useAppDispatch();

  const actions = useMemo(
    () => ({
      get: async (id: number): Promise<{ data: IPaymentMethodCurrency } | undefined> => {
        const paymentMethodCurrency = await dispatch(getPaymentMethodCurrencies(id));
        return paymentMethodCurrency.payload as Promise<{ data: IPaymentMethodCurrency } | undefined>;
      },
      getLocalById: (id: number) => state.data.data.find((paymentMethodCurrency) => paymentMethodCurrency.id === id),
      save: (options: ISaveOptions<IPaymentMethodCurrency>): void => {
        dispatch(paymentMethodCurrencySlice.actions.save(options));
      },
      addPaymentMethodCurrency: (model: IPaymentMethodCurrency): void => {
        dispatch(paymentMethodCurrencySlice.actions.addPaymentMethodCurrency(model));
      },
    }),
    [dispatch, state.data.data],
  );

  return [state, actions];
};

export { paymentMethodCurrencySlice };

export default usePaymentMethodCurrency;
