import { FieldValues } from 'react-hook-form';
import { useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';

import ArrowDownIcon from 'components/common/icons/ArrowDown';
import ArrowUpIcon from 'components/common/icons/ArrowUp';

import FormNumberInput, { FormNumberFieldProps } from '../NumberField';

interface MenuOption {
  value: string;
  label: string;
}

interface FormNumberInputWithMenuProps<TFieldValues extends FieldValues = FieldValues>
  extends FormNumberFieldProps<TFieldValues> {
  menuOptions: MenuOption[];
  defaultMenuOption?: MenuOption;
  onMenuChange: (value: string) => void;
}

const FormNumberInputWithMenu = <TFieldValues extends FieldValues = FieldValues>({
  menuOptions,
  defaultMenuOption = menuOptions[0],
  onMenuChange,
  ...rest
}: FormNumberInputWithMenuProps<TFieldValues>): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpened = Boolean(anchorEl);
  const [selectedMenuOption, setSelectedMenuOption] = useState(defaultMenuOption as MenuOption);

  return (
    <FormNumberInput
      {...rest}
      InputProps={{
        startAdornment: (
          <>
            <Button
              variant="text"
              id="basic-button"
              aria-haspopup="true"
              color="inherit"
              endIcon={isMenuOpened ? <ArrowUpIcon /> : <ArrowDownIcon />}
              aria-expanded={isMenuOpened ? 'true' : undefined}
              aria-controls={isMenuOpened ? 'basic-menu' : undefined}
              onClick={(event): void => setAnchorEl(event.currentTarget)}
            >
              {selectedMenuOption.label}
            </Button>
            {!rest.disabled && isMenuOpened && (
              <Menu
                open
                anchorEl={anchorEl}
                onClose={(): void => setAnchorEl(null)}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {menuOptions.map((menuOption) => (
                  <MenuItem
                    key={menuOption.value}
                    onClick={(): void => {
                      setSelectedMenuOption(menuOption);
                      onMenuChange(menuOption.value);
                      setAnchorEl(null);
                    }}
                  >
                    {menuOption.label}
                  </MenuItem>
                ))}
              </Menu>
            )}
          </>
        ),
      }}
    />
  );
};

export default FormNumberInputWithMenu;
