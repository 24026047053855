import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const GalleryAddIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon width={18} height={18} viewBox="0 0 18 18" {...props}>
    <path
      d="M6.13805 12.4018L6.14 12.4005C6.44939 12.1917 6.89614 12.2266 7.14622 12.445L7.15493 12.4526L7.16386 12.46L7.41315 12.6653C8.29817 13.4192 9.68093 13.4173 10.5634 12.6595C10.5635 12.6594 10.5636 12.6594 10.5637 12.6593L13.6834 9.98202L13.6837 9.98178C13.9689 9.7368 14.471 9.7368 14.7561 9.98178L14.7562 9.9818L15.6999 10.7924V12.1424C15.6999 13.3483 15.3436 14.2149 14.7792 14.7792C14.2149 15.3436 13.3483 15.6999 12.1424 15.6999H5.85742C4.50498 15.6999 3.5595 15.2549 2.98947 14.5137L6.13805 12.4018Z"
      fill="inherit"
      stroke="inherit"
      strokeWidth="1.6"
    />
    <path
      opacity="0.4"
      d="M15.7275 6H13.5225C12.57 6 12 5.43 12 4.4775V2.2725C12 1.9725 12.06 1.7175 12.165 1.5C12.1575 1.5 12.15 1.5 12.1425 1.5H5.8575C3.1275 1.5 1.5 3.1275 1.5 5.8575V12.1425C1.5 12.96 1.6425 13.6725 1.92 14.2725L2.0025 14.2125L5.6925 11.7375C6.2925 11.3325 7.14 11.3775 7.6725 11.8425L7.9275 12.0525C8.5125 12.555 9.4575 12.555 10.0425 12.0525L13.1625 9.375C13.7475 8.8725 14.6925 8.8725 15.2775 9.375L16.5 10.425V5.8575C16.5 5.85 16.5 5.8425 16.5 5.835C16.2825 5.94 16.0275 6 15.7275 6Z"
      fill="inherit"
    />
    <path
      d="M6.74984 7.78509C7.73567 7.78509 8.53484 6.98592 8.53484 6.00009C8.53484 5.01426 7.73567 4.21509 6.74984 4.21509C5.76402 4.21509 4.96484 5.01426 4.96484 6.00009C4.96484 6.98592 5.76402 7.78509 6.74984 7.78509Z"
      fill="inherit"
    />
    <path
      d="M15.7275 0.75H13.5225C12.57 0.75 12 1.32 12 2.2725V4.4775C12 5.43 12.57 6 13.5225 6H15.7275C16.68 6 17.25 5.43 17.25 4.4775V2.2725C17.25 1.32 16.68 0.75 15.7275 0.75ZM16.4325 3.6975C16.3575 3.7725 16.245 3.825 16.125 3.8325H15.0675L15.075 4.875C15.0675 5.0025 15.0225 5.1075 14.9325 5.1975C14.8575 5.2725 14.745 5.325 14.625 5.325C14.3775 5.325 14.175 5.1225 14.175 4.875V3.825L13.125 3.8325C12.8775 3.8325 12.675 3.6225 12.675 3.375C12.675 3.1275 12.8775 2.925 13.125 2.925L14.175 2.9325V1.8825C14.175 1.635 14.3775 1.425 14.625 1.425C14.8725 1.425 15.075 1.635 15.075 1.8825L15.0675 2.925H16.125C16.3725 2.925 16.575 3.1275 16.575 3.375C16.5675 3.5025 16.515 3.6075 16.4325 3.6975Z"
      fill="inherit"
    />
  </SvgIcon>
);

export default GalleryAddIcon;
