import { FC, useState } from 'react';
import Button from '@mui/material/Button';
import { AffiliatePermissionsEnum } from 'enums/accessTemplates';

import { translate } from 'utils/translate';
import { AccessGetLinkPermissions } from 'utils/accessTemplates';
import { OptionType } from 'types';
import useMount from 'hooks/useMount';
import { getActiveDomains } from 'api/requests/domains';

import GetLinkModal from '../MainModals/GetLinkModal';

type Props = {
  permission: AffiliatePermissionsEnum;
};

const PopupMenu: FC<Props> = ({ permission }) => {
  const [open, setOpen] = useState(false);
  const [linkOptions, setLinkOptions] = useState<OptionType[]>([]);

  const openPopup = (): void => setOpen(true);

  useMount(() => {
    (async (): Promise<void> => {
      try {
        if (linkOptions?.length === 0) {
          const response = await getActiveDomains();
          const options = response.data?.map((i) => {
            return { id: i.name, name: i.name };
          });
          setLinkOptions(options ?? []);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  });

  return (
    <>
      {AccessGetLinkPermissions.some((r) => r === permission) && (
        <Button size="small" variant="outlined" color="primary" onClick={openPopup}>
          {translate('Get link')}
        </Button>
      )}
      {open && <GetLinkModal linkOptions={linkOptions} onClose={(): void => setOpen(false)} />}
    </>
  );
};

export default PopupMenu;
