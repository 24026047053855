import { authSlice } from './auth';
import { brandingSlice } from './branding';
import { partnersSlice } from './partner';
import { playerSlice } from './player';
import { affiliateSlice } from './affiliate';
import { allCountriesSlice } from './countries';
import { currenciesSlice } from './currencies';
import { infoSlice } from './info';
import { configurationSlice } from './configuration';
import { paymentMethodSlice } from './paymentMethod';
import { paymentMethodCurrencySlice } from './paymentMethodCurrency';
import { partnerPaymentMethodSlice } from './partnerPaymentMethod';
import { settingsPaymentMethodSlice } from './settingsPaymentMethod';
import { paymentMethodCustomFieldSlice } from './paymentMethodCustomFields';
import { settingsPaymentMethodCurrencySlice } from './settingsPaymentMethodCurrency';

export const partners = partnersSlice.reducer;
export const auth = authSlice.reducer;
export const branding = brandingSlice.reducer;
export const player = playerSlice.reducer;
export const paymentMethod = paymentMethodSlice.reducer;
export const settingsPaymentMethodCurrency = settingsPaymentMethodCurrencySlice.reducer;
export const settingsPaymentMethod = settingsPaymentMethodSlice.reducer;
export const paymentMethodCustomField = paymentMethodCustomFieldSlice.reducer;
export const partnerPaymentMethod = partnerPaymentMethodSlice.reducer;
export const paymentMethodCurrency = paymentMethodCurrencySlice.reducer;
export const affiliate = affiliateSlice.reducer;
export const allCountries = allCountriesSlice.reducer;
export const currencies = currenciesSlice.reducer;
export const info = infoSlice.reducer;
export const configuration = configurationSlice.reducer;
