import { AffiliatePermissionsEnum, PermissionTemplateTypeEnum } from 'enums/accessTemplates';
import { ActivityTypeEnum, PeriodEnum } from 'enums/dashboard';
import { AffiliateBetCategoryTypeEnum, TransferTypeEnum } from 'enums/entity';
import { BalanceTypeEnum } from 'enums/payment';

export const STATUSES_OPTIONS = [
  { id: 'ACTIVE', name: 'Active' },
  { id: 'BLOCK', name: 'Blocked' },
  { id: 'PENDING', name: 'Pending' },
  { id: 'UNVERIFIED', name: 'Unverified' },
];

export const VERIFICATION_STATUSES_OPTIONS = [
  { id: '1', name: 'Verified' },
  { id: '2', name: 'Not verified' },
  { id: '3', name: 'Awaiting verified' },
];

export const GENDER_OPTIONS = [
  { id: '1', name: 'Male' },
  { id: '2', name: 'Female' },
];

export const PREMIUM_OPTIONS = [
  { id: 'true', name: 'Premium' },
  { id: 'false', name: 'Basic' },
];

export const YES_OR_NO_OPTIONS = [
  { id: 'true', name: 'Yes' },
  { id: 'false', name: 'No' },
];

export const RATIOS = [
  {
    id: 1,
    name: '16:9',
  },
  {
    id: 2,
    name: '4:3',
  },
];

export const CHANNELS = [
  {
    id: 0,
    name: 'All',
  },
  {
    id: 1,
    name: 'Web',
  },
  {
    id: 2,
    name: 'Mobile',
  },
  {
    id: 3,
    name: 'Tablet',
  },
  {
    id: 4,
    name: 'Backend',
  },
];

export const TRANSFER_TYPE_OPTIONS = [
  {
    id: TransferTypeEnum.DEPOSIT,
    name: 'Deposit',
  },
  {
    id: TransferTypeEnum.WITHDRAW,
    name: 'Withdraw',
  },
];

export const ACCESS_TYPE_OPTIONS = [
  {
    id: PermissionTemplateTypeEnum.PUBLIC,
    name: 'Public',
    permissions: [AffiliatePermissionsEnum.SUPER_ADMIN, AffiliatePermissionsEnum.ADMIN],
  },
  {
    id: PermissionTemplateTypeEnum.PRIVATE,
    name: 'Private',
  },
  {
    id: PermissionTemplateTypeEnum.LAYERED,
    name: 'Layered',
    permissions: [AffiliatePermissionsEnum.SUPER_ADMIN, AffiliatePermissionsEnum.ADMIN],
  },
];

export const PERMISSIONS_OPTIONS = [
  {
    id: AffiliatePermissionsEnum.SUPER_ADMIN,
    name: 'Super Admin',
  },
  {
    id: AffiliatePermissionsEnum.ADMIN,
    name: 'Admin',
  },
  {
    id: AffiliatePermissionsEnum.AFFILIATE_USER,
    name: 'Affiliate verified read',
  },
];

export const periodicalOptions = [
  {
    id: PeriodEnum.TODAY,
    name: 'Today',
  },
  {
    id: PeriodEnum.YESTERDAY,
    name: 'Yesterday',
  },
  {
    id: PeriodEnum.WEEK,
    name: 'This week',
  },
  {
    id: PeriodEnum.LAST_WEEK,
    name: 'Last week',
  },
  {
    id: PeriodEnum.MONTH,
    name: 'This month',
  },
  {
    id: PeriodEnum.LAST_MONTH,
    name: 'Last month',
  },
];

export const categoryTypeOptions = [
  {
    id: AffiliateBetCategoryTypeEnum.CASINO,
    name: 'Casino',
  },
  {
    id: AffiliateBetCategoryTypeEnum.SPORT,
    name: 'Sport',
  },
];

export const adminWithdrawTypeOptions = [
  { id: BalanceTypeEnum.CPA, name: BalanceTypeEnum.CPA, key: ActivityTypeEnum.CPA_BALANCE },
  { id: BalanceTypeEnum.REVENUE, name: BalanceTypeEnum.REVENUE, key: ActivityTypeEnum.REVENUE_BALANCE },
];
